@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$marginSmall: 10px;
$marginMedium: 16px;
$marginLarge: 32px;

$marginBottomDevice: 48px;
$marginBottomDesktop: 68px;

$borderRadiusSmall: 12px;
$borderRadiusMedium: 16px;
$borderRadiusLarge: 24px;

$imageDesktopFixedDimension: 180px;
$imageDeviceFixedDimension: 88px;

$imageWidth: 40.761%;

$minScreenWidth: 414px;

$fontLineHeightCardHeader: fluid-size(24px, 40px, theme.$minViewportWidth, theme.$maxViewportWidth);

.card {
  background-color: theme.$green-primary;

  .image {
    width: $imageWidth;
    aspect-ratio: 1 / 1;
    border-radius: $borderRadiusLarge;
  }

  .cardContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: theme.$green-primary;
  }

  .tagline {
    font-family: website.$amazonEmberMono;
    color: theme.$green-primary;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
  }

  &.dark {
    background-color: theme.$slate-dark;

    .cardContent {
      background-color: theme.$slate-dark;
    }

    .tagline {
      background-color: rgba(theme.$white, 0.05);
      color: theme.$green-secondary;
    }
  }
}

kat-card.card {
  cursor: pointer;
  --background: #{theme.$grey-primary};
  --color: #{theme.$green-primary};
  --kat-text-link: #{theme.$green-primary};
  --kat-text-link-hover: #{theme.$green-primary};
  --border-style: none;
  --box-shadow: none;
  --base-gap: 0;
  --base-padding: #{$marginMedium};

  &.dark {
    --background: #{theme.$slate-dark};
    --color: #{theme.$green-secondary};
    --kat-text-link: #{theme.$green-secondary};
    --kat-text-link-hover: #{theme.$green-secondary};
  }
}
