@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$desktopDarkTextWrapperMaxWidth: 736px;
$desktopLightTextWrapperMaxWidth: 888px;

$xHeightOffset: 0.75ex;

.TextComponent {
  display: flex;
  justify-content: space-around;
  column-gap: 100px;
  flex-direction: column;

  @include theme.small-desktop {
    column-gap: fluid-size(32px, 100px, theme.$largeDevice, theme.$maxViewportWidth);
    flex-direction: row;
    margin-bottom: 50px;
    &.entryPoint {
      margin-top: #{6 * $xHeightOffset};
      column-gap: 0;
      justify-content: space-between;
    }
  }

  .textWrapper {
    @include theme.small-desktop {
      &.dark {
        max-width: $desktopDarkTextWrapperMaxWidth;
      }

      &.light {
        max-width: $desktopLightTextWrapperMaxWidth;
      }
    }
  }

  .contactUsWrapper {
    .heading {
      @include theme.small-desktop {
        transform: translateY(-$xHeightOffset);
      }
    }

    .content {
      @include theme.small-desktop {
        transform: translateY(-#{4 * $xHeightOffset});
      }
    }
  }

  .heading {
    flex: 1;

    &.alignRight {
      text-align: right;
    }
  }

  .content {
    flex: 1;
    margin-block-start: 24px;

    @include theme.medium-device {
      margin-block-start: 27px;
    }
  }

  .description {
    margin: 0 0 40px !important;
    font-size: theme.$fontSizeBody !important;
    line-height: theme.$fontLineHeightBody !important;

    @include theme.medium-device {
      margin-block-start: 56px;
    }
  }

  .ctasContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include theme.small-desktop {
      flex-direction: row;
      align-items: center;
    }
  }

  .primaryCta {
    margin-block-end: 44px;
    height: 80px;

    @include theme.small-desktop {
      margin-inline-end: 40px;
      margin-block-end: 0;
    }
  }

  &.dark {
    .heading,
    .description,
    .externalLink {
      color: rgba(theme.$green-secondary, 0.85);
    }
    .secondaryCta,
    .highlightedText {
      color: rgba(theme.$green-secondary, 0.85);
    }
  }

  &.light {
    .heading,
    .description,
    .externalLink,
    .secondaryCta {
      color: rgba(theme.$green-primary, 0.85);
    }
    .highlightedText {
      color: rgba(theme.$green-primary, 0.85);
    }
  }
}
