@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$borderRadius: 2px;
$iconMargin: 4px;
$iconSize: 24px;
$underlineHeight: 2px;

.externalLink {
  text-decoration: none;
  &:focus-visible {
    border-radius: $borderRadius;
  }
  &:hover {
    text-decoration: none !important;
  }
}

.textWrapper {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  padding-bottom: 5px;

  &.noUnderline {
    text-decoration: none;
    padding-bottom: 0;
  }

  &:hover {
    text-decoration-thickness: 3px;
    text-underline-offset: 4px;
  }

  /* Firefox-specific styles */
  @supports (-moz-appearance: none) {
    &:hover {
      text-decoration-thickness: 3px;
      text-underline-offset: 6px;
    }
  }
}

.text {
  position: relative;
  font-size: theme.$fontSizeCta;
  line-height: 24px;
  font-family: website.$amazonEmberDisplay;
}

.bold {
  font-weight: bold;
}

.lastWord {
  white-space: nowrap;
}

.iconsWrapper {
  @include theme.size($iconSize);
  display: inline-block;
  vertical-align: middle;

  &.iconsWrapperOutlined {
    margin-inline-start: $iconMargin;
    color: theme.$green-primary;
    background-color: theme.$grey-secondary;
    border-radius: 100%;
  }
}

.icon {
  transform: scale(1);
  transform-origin: right top;
  svg {
    @include theme.size(100%);
  }
}
