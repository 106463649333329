@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

.contentWrapper {
  padding-right: 8px;

  @include theme.medium-device {
    padding-right: 12px;
  }
}
.modalQuoteContainer {
  padding: 12px 72px 0 16px;
  .quote {
    font-size: theme.$fontSizeHeading05 !important;
    line-height: theme.$fontLineHeightHeading05 !important;
    text-align: start !important;
    span {
      font-size: theme.$fontSizeHeading05 !important;
      line-height: theme.$fontLineHeightHeading05 !important;
    }
  }
  @include theme.medium-device {
    padding: 0 125px 0 52px;
  }
}
.tagline {
  font-family: website.$amazonEmberMono;
  margin-bottom: theme.fluid-size(30px, 54px, theme.$minViewportWidth, theme.$maxViewportWidth);
  color: theme.$green-primary;
}
