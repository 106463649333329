@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

.contentWrapper {
  padding-right: 8px;
  position: relative;
  height: 100%;
  @include theme.medium-device {
    padding-right: 12px;
  }
}

.modalStatsContainer {
  display: flex;
  flex-direction: column;
  color: theme.$green-primary;
  padding: 24px 9px 0 24px;

  @include theme.medium-device {
    padding: 0 16px 0 52px;
  }
}
.tagline {
  font-family: website.$amazonEmberMono;
  margin-bottom: 32px;

  @include theme.medium-device {
    margin-bottom: 48px;
  }
}
.modalStats {
  display: flex;
  flex-direction: column;
  gap: theme.fluid-size(16px, 32px, theme.$minViewportWidth, theme.$maxViewportWidth);
}

.modalStatsElement {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 40px;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 97, 97, 0.2);
  }
  @include theme.medium-device {
    gap: 0;
    &:not(:last-of-type) {
      border-bottom: none;
    }
  }
}

.modalStatsHeading {
  max-width: 357px;
  margin-bottom: 32px !important;

  @include theme.medium-device {
    max-width: 500px;
    margin-bottom: 56px !important;
  }
}
.copyWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include theme.medium-device {
    .modalStatsElement:not(:last-of-type) & {
      border-bottom: 1px solid rgba(0, 97, 97, 0.2);
    }

    margin-right: theme.fluid-size(0px, 64px, theme.$minViewportWidth, theme.$maxViewportWidth);
    width: 100%;
  }
  h4 {
    margin: 0 !important;
    color: theme.$green-primary;
  }
  p {
    color: theme.$green-primary;
  }
}
.statsCopy {
  font-size: theme.$fontSizeBodySmall !important;
  line-height: theme.$fontLineHeightBodySmall !important;
  opacity: theme.$asxCopyOpacity;

  //  max-width: theme.fluid-size(261px, 450px, theme.$minViewportWidth, theme.$maxViewportWidth);
  margin-bottom: theme.fluid-size(16px, 32px, theme.$minViewportWidth, theme.$maxViewportWidth);
  @include theme.medium-device {
    width: 82%;
  }
}
.image {
  width: theme.fluid-size(56px, 136px, theme.$minViewportWidth, theme.$maxViewportWidth);
  height: theme.fluid-size(56px, 88px, theme.$minViewportWidth, theme.$maxViewportWidth);
  border-radius: theme.fluid-size(12px, 20px, theme.$minViewportWidth, theme.$maxViewportWidth);
  opacity: 0.9;
}

.gradient {
  height: 105px;
  width: calc(100% - 20px);
  background: linear-gradient(180deg, rgba(242, 247, 247, 0) 0%, #f2f7f7 100%);
  position: absolute;
  bottom: -1px;
  pointer-events: none;
  transform-origin: bottom center;
  transition: height 0.3s 0s;
  @include theme.medium-device {
    height: 153px;
  }
  &.scrollEnd {
    height: 0;
  }
}
