@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use '../../../index.scss' as website;

$quoteContainerHeight: 100vh;

$mobileQuoteContainerEyebrowMargin: 0 auto 32px auto;
$mobileQuoteContainerPadding: 40px 25px;
$mobileQuoteContainerQuoteMargin: 48px 0 auto 0;
$mobileBorderRadius: 24px 24px 0;
$mobileQuoteContainerScrollDownMarginAlone: 0 0 3.5vh;

$desktopQuoteContainerEyebrowMargin: 10vh auto 48px auto;
$desktopQuoteContainerQuoteMargin: 72px 0 auto 0;
$desktopQuoteContainerQuoteMaxWidth: 1192px;
$desktopQuoteContainerScrollDownMargin: 20vh 0 0 0;
$desktopBorderRadius: 0;

$desktopQuoteContainerScrollDownMarginAlone: 0 0 9vh;

.quoteContainer {
  height: $quoteContainerHeight;
  padding: $mobileQuoteContainerPadding;
  background: theme.$slate-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: theme.zindex(website.$zLayout, lastComponent);

  @include theme.small-desktop {
    padding: 0;
    justify-content: center;
    overflow-x: clip;
  }
}

.backgroundImageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  border-radius: $mobileBorderRadius;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(theme.$slate-dark, 0.6) 40%,
      rgba(theme.$slate-dark, 0.6) 60%,
      theme.$slate-dark 100%
    );
  }

  @include theme.small-desktop {
    border-radius: $desktopBorderRadius;
  }
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.eyebrow {
  margin: $mobileQuoteContainerEyebrowMargin !important;
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  text-align: center;
  color: theme.$grey-secondary;
  opacity: theme.$asxCopyOpacity;
  z-index: 1;

  @include theme.small-desktop {
    margin: $desktopQuoteContainerEyebrowMargin !important;
  }
}

.quoteWrapper {
  margin: $mobileQuoteContainerQuoteMargin !important;
  text-align: center;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s ease;

  &.fadeOut {
    opacity: 0;
  }

  &.fadeIn {
    opacity: 1;
  }

  @include theme.small-desktop {
    margin: $desktopQuoteContainerQuoteMargin !important;
    max-width: $desktopQuoteContainerQuoteMaxWidth;
    transition: opacity 0.5s ease;
  }
}

.quote {
  color: theme.$white;
  margin: 0 !important;
}

.scrollDown {
  font-family: website.$amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$white;
  opacity: 0.8;
  z-index: 1;

  &.standAlone {
    margin: $mobileQuoteContainerScrollDownMarginAlone !important;
    @include theme.small-desktop {
      margin: $desktopQuoteContainerScrollDownMarginAlone !important;
    }
  }

  @include theme.small-desktop {
    margin: $desktopQuoteContainerScrollDownMargin !important;
  }
}
