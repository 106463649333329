@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$videoBackgroundColor: #000;

.videoPlayerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    background-color: $videoBackgroundColor;
    width: 100%;
    height: 100%;
  }

  &:fullscreen {
    background-color: $videoBackgroundColor;
  }

  &:-webkit-full-screen {
    background-color: $videoBackgroundColor;
  }

  &:-moz-full-screen {
    background-color: $videoBackgroundColor;
  }
}

.nativeVideoPlayer {
  border-radius: 24px;
  aspect-ratio: 16 / 9;
  max-height: 95%;
  max-width: 95%;
}
