@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$mobileSpacing: 0 25px 25px;
$mediumDeviceSpacing: 0 64px 64px;
$smallDesktopSpacing: 0 60px 60px;
$gridSmallDesktopColumnGap: 24px;

$desktopCardsBorderRadius: 32px;

$secondCardAspectRatio: #{0.73 / 1};

.cardsDashboard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: $mobileSpacing;
  background-color: theme.$grey-secondary;

  @include theme.medium-device {
    padding: $mediumDeviceSpacing;
  }

  @include theme.small-desktop {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: repeat(12, 1fr);
    column-gap: $gridSmallDesktopColumnGap;
    padding: $smallDesktopSpacing;
  }

  .card {
    width: 100%;
    border-radius: $desktopCardsBorderRadius;
  }

  // First card
  .card:nth-child(1) {
    // TODO: We hide them this way to avoid altering the grid positioning by
    // conditionally rendering the images, this would need to be refactored in the future
    // https://issues.amazon.com/issues/ASXBug-204
    visibility: hidden;
    height: 0;

    @include theme.small-desktop {
      visibility: visible;
      height: unset;
      order: 3;
      grid-area: 3 / 2 / -1 / 5;
      aspect-ratio: 1.08 / 1;
    }
  }

  // Second card
  .card:nth-child(2) {
    order: 1;
    grid-area: 1 / 5 / -1 / 9;
    aspect-ratio: $secondCardAspectRatio;

    @include theme.medium-device {
      aspect-ratio: auto;
    }

    @include theme.small-desktop {
      aspect-ratio: $secondCardAspectRatio;
    }

    .cardHeading {
      color: theme.$green-secondary;
      margin-bottom: 32px;
    }

    .cardDescription {
      color: theme.$grey-secondary;
      font-size: theme.$fontSizeSubtitle !important;
      line-height: theme.$fontLineHeightSubtitle !important;

      @include theme.small-desktop {
        max-width: 75%;
      }
    }

    .cardGraphic {
      width: 100%;
      aspect-ratio: 1 / 1;
      align-self: end;
      margin-top: auto;
    }

    .cardImage {
      width: 50%;
      aspect-ratio: 1 / 1;
      align-self: end;
      margin-top: auto;
      border-radius: $desktopCardsBorderRadius;

      @include theme.medium-device {
        width: 35%;
      }

      @include theme.small-desktop {
        width: 50%;
      }
    }

    .cardImageDescription {
      color: theme.$grey-secondary;
      font-size: theme.$fontSizeSubtitle !important;
      line-height: theme.$fontLineHeightSubtitle !important;

      @include theme.medium-device {
        margin-bottom: 32px;
      }

      @include theme.small-desktop {
        margin-bottom: 0;
        max-width: 75%;
      }
    }
  }

  // Third card
  .card:nth-child(3) {
    order: 2;
    grid-area: 2 / 9 / 9 / 12;
    aspect-ratio: 1.54 / 1;

    .cardContent {
      margin-top: auto;
    }

    .cardHeading {
      color: theme.$green-secondary;
      font-size: theme.$fontSizeHeading02 !important;
      line-height: theme.$fontLineHeightHeading02 !important;

      @include theme.small-desktop {
        aspect-ratio: unset;
        margin-bottom: 16px;
      }

      @include theme.large-desktop {
        margin-bottom: 32px;
      }
    }

    .cardDescription {
      color: theme.$grey-secondary;
      opacity: 0.85;
      font-size: theme.$fontSizeBody !important;
      line-height: theme.$fontLineHeightBody !important;
      margin: 0px !important;
    }

    @media (min-width: website.$maxViewportWidth) {
      aspect-ratio: 1.13 / 1;
      height: 100%;
    }
  }

  // Fourth card
  .card:nth-child(4) {
    visibility: hidden;
    height: 0;

    @include theme.small-desktop {
      order: 4;
      visibility: visible;
      height: unset;
      grid-area: 9 / 9 / 12 / 11;
    }
  }
}
