@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$largeDeviceDynamicListMiddleSpace: 100px;
$largeDesktopDynamicListMiddleSpaceSvg: 208px;
$largeDesktopDynamicListMiddleSpace: 176px;

$headingBottomSpaceSmall: 8px;
$headingBottomSpaceLessThanLargeDevice: 48px;
$headingBottomSpaceMoreThanLargeDevice: 64px;

$subtitleBottomSpaceSmallDevice: 84px;
$subtitleBottomSpaceMediumDevice: 84px;

$imageTopPosition: 152px;
$imageTopPositionCollapsed: 79px;
$imageTopPositionPaginator: 196px;
$imageBottomSpace: 125px;

.dynamicList {
  display: flex;
  flex-direction: column;
  overflow: clip;
  padding-bottom: 10px;
  color: theme.$green-primary;

  @include theme.large-device {
    gap: theme.fluid-size(
      $largeDeviceDynamicListMiddleSpace,
      $largeDesktopDynamicListMiddleSpace,
      theme.$largeDevice,
      theme.$maxViewportWidth
    );
    justify-content: space-between;
    flex-direction: row;
  }

  &.svgVariant {
    @include theme.large-device {
      gap: theme.fluid-size(
        $largeDeviceDynamicListMiddleSpace,
        $largeDesktopDynamicListMiddleSpaceSvg,
        theme.$largeDevice,
        theme.$maxViewportWidth
      );
    }
  }
}

.imageContainer {
  z-index: theme.zindex(website.$zLayout, dynamicListImage);
  height: fit-content;
  color: theme.$green-primary;
  display: flex;
  gap: $imageBottomSpace;
  flex-direction: column;
  padding-top: 16px;

  @include theme.large-device {
    //Responsive layout
    flex-basis: 48.5%;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    top: $imageTopPositionPaginator;
    position: sticky;
  }
}
.heading {
  color: theme.$green-primary;
  padding-bottom: $headingBottomSpaceSmall;

  @include theme.small-device {
    padding-bottom: theme.fluid-size(
      $headingBottomSpaceSmall,
      $headingBottomSpaceLessThanLargeDevice,
      theme.$smallDevice,
      theme.$largeDevice
    );
  }

  @include theme.large-device {
    padding-bottom: $headingBottomSpaceMoreThanLargeDevice;
  }
}

.subtitle {
  color: theme.$green-primary;
  padding-bottom: $subtitleBottomSpaceSmallDevice;
  opacity: theme.$asxCopyOpacity;

  @include theme.medium-device {
    padding-bottom: $subtitleBottomSpaceMediumDevice;
  }
}

.list {
  display: flex;
  flex-direction: column;
  position: relative;

  @include theme.large-device {
    flex-basis: 51.5%;
    z-index: 3;
  }

  @include theme.small-desktop {
    z-index: unset;
  }
}

.pageIndicator {
  align-self: flex-end;
  font-family: website.$amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
}

.DynamicListSVG {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  svg {
    max-height: 40vh;
  }
}

.svgMobile {
  position: sticky;
  top: $imageTopPosition;
  background-color: theme.$grey-tertiary;
  padding-top: 28px;
  z-index: 1;

  &.collapse {
    top: $imageTopPositionCollapsed;
  }
}

.fixedTitle {
  right: 0;
  top: 17vh;
  position: sticky;
  display: grid;
  gap: theme.fluid-size(1px, 72px, theme.$largeDevice, theme.$maxViewportWidth);
  width: 100%;
  z-index: 1;
  padding: 10px 0 16px;
  color: theme.$green-primary;
  background-color: theme.$grey-secondary;
  z-index: 4;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 100%;
    background-color: theme.$grey-secondary;
    // we fix a safari bug by adding translate3d: https://forum.framework7.io/t/css-sticky-element-is-flickering/10566
    transform: translate3d(0, 0, 0);
  }
}
.fixedTaglineAndTitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include theme.large-device {
    gap: 32px;
  }
}
