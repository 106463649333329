@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$mobileAnchorFontWeight: 700;
$desktopAnchorFontWeight: 400;
$regularOpacity: 0.85;
$hoverOpacity: 1;
$linkAndIconSpacing: 4px;
$darkIconBackgroundOpacity: 0.05;

.markdown-copy {
  a {
    display: inline-flex;
    align-items: center;
    column-gap: $linkAndIconSpacing;
    font-family: website.$amazonEmberDisplay;
    font-weight: $mobileAnchorFontWeight !important;
    color: theme.$green-primary !important;
    text-decoration: underline !important;

    .icon {
      border-radius: 100%;
      background-color: theme.$white;
      color: theme.$green-primary;
      display: flex;
      justify-content: center;
      align-items: center;

      // Prevents flickering glitch of the background color on hover at the very edge of the icon with a mouse
      svg{
        width: 100%;
        height: 100%;
      }
    }

    &:hover,
    &:focus {
      opacity: $hoverOpacity;
    }

    &.dark {
      .icon {
        background-color: rgba(theme.$white, $darkIconBackgroundOpacity);
        color: theme.$white;
      }

      color: theme.$grey-secondary !important;

      &:hover,
      &:focus {
        .icon {
          background-color: theme.$white;
          color: theme.$green-primary;
        }
      }
    }

    @include theme.small-desktop {
      font-weight: $desktopAnchorFontWeight !important;
      opacity: $regularOpacity;
    }
  }
}
