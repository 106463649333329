@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

.modalBodyScroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: theme.$grey-tertiary;
    border-radius: 2px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(theme.$green-primary, 0.65);
    border-radius: 2px;
    min-height: 200px;
  }
}
