@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$textPadding: 25px;

$imagesMobileWidth: 28.5%;

$outroSectionMobileWidth: 80vw;
$outroSectionMediumDeviceWidth: 85vw;
$outroSectionSmallDesktopWidth: 75vw;
$outroSectionMediumDesktopWidth: 65vw;

// For this component specifically specs are different for the cta
$ctaMobilePadding: 32px;
$ctaDesktopPadding: 82px;

$textMaxWidth: 50%;

$linkMarginTopMobile: 16px;
$linkMarginTopSmallDesktop: 0;

.outro {
  position: relative;
  height: 100vh;
  z-index: theme.zindex(website.$zLayout, outroComponent);
  background-color: theme.$slate-dark;
  overflow: hidden;
  border-bottom: 1px solid rgba(theme.$white, 0.3);
}

.outroSection {
  opacity: 1;
  position: absolute;
  width: $outroSectionMobileWidth;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  @include theme.medium-device {
    max-width: $outroSectionMediumDeviceWidth;
  }

  @include theme.small-desktop {
    max-width: $outroSectionSmallDesktopWidth;
  }

  @include theme.medium-desktop {
    max-width: $outroSectionMediumDesktopWidth;
  }
}

.outroTitle {
  color: theme.$grey-secondary;
  text-align: center;
  margin-bottom: 48px !important;
}

.highlight {
  color: theme.$green-secondary;
}

.description {
  color: theme.$grey-secondary;
  text-align: center;
  margin-bottom: 64px !important;

  @include theme.small-desktop {
    max-width: $textMaxWidth;
  }
}

.ctasContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 48px !important;

  @include theme.small-desktop {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

// Specifically this cta has a different style
.firstCta {
  background-color: rgba(theme.$white, 0.05) !important;
}

.cta {
  pointer-events: none;
}

.link {
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  color: theme.$grey-secondary !important;
  text-decoration: underline !important;
  margin-top: $linkMarginTopMobile;

  @include theme.small-desktop {
    margin-top: $linkMarginTopSmallDesktop;
  }
}

.clickEnabled {
  pointer-events: all;
}
