@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$marginLarge: 40px;
$marginXLarge: 60px;
$marginMedium: 24px;
$marginSmall: 16px;
$marginXSmall: 10px;
$rowGap: 32px;
$linkMarginBottomDevice: 2px;
$paddingSmall: 30px;
$paddingMedium: 48px;
$textMarginBottom: 36px;

$minHeight: 100px;
$maxHeight: 247px;
$minWidth: 144px;
$maxWidth: 360px;

$columnGapLarge: 95px;
$columnGapMedium: 64px;

$maxColumnGapDevice: 220px;
$maxColumnGapDesktop: 100px;

$deviceMaxView: 768px;

$maxWidthMax: 880px;
$maxWidthMin: 400px;

$desktopVideoAccordionTopPadding: 24px;
$desktopVideoAccordionRightPadding: 86px;
$desktopVideoAccordionBottomPadding: 40px;
$desktopVideoAccordionGap: 60px;
$desktopVideoContainerWidth: 350px;
$desktopVideoWrapperHeight: 218px;
$desktopVideoWrapperBorderRadius: 24px;

.listComponent {
  background-color: theme.$grey-secondary;
  display: flex;
  flex-direction: column;
  row-gap: $marginLarge;
  padding: 0;

  @include theme.medium-device {
    padding: 0 $marginXLarge;
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: $marginSmall;
}

.listItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: theme.fluid-size(0px, $maxColumnGapDevice, theme.$largeDevice, theme.$mediumDesktop);

  &.light {
    border-bottom-color: rgba(theme.$green-primary, 0.4);
  }
  &.dark {
    border-bottom-color: rgba(theme.$green-secondary, 0.4);
  }
  &.list {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: $paddingSmall 0 $marginSmall;
  }
  &.accordion {
    padding: $rowGap 0;
  }

  @include theme.large-device {
    flex-direction: row;
    column-gap: theme.fluid-size($rowGap, $minHeight, theme.$largeDevice, theme.$maxViewportWidth);

    &.list {
      padding: $paddingSmall 0;
    }

    &.accordion {
      padding: $paddingMedium 0;
    }
  }

  .textContentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;

    .taglines {
      display: flex;
      gap: $marginSmall;
      margin-bottom: $marginSmall;
      flex-wrap: wrap;

      @include theme.medium-device {
        margin-bottom: $marginMedium;
      }
    }

    h6.title {
      font-weight: theme.$normal-font-weight !important;
      font-size: theme.$fontSizeSubtitle !important;
      line-height: theme.$fontLineHeightSubtitle !important;
      margin-bottom: $marginSmall !important;
    }

    .text {
      margin-bottom: $textMarginBottom;
      &.hasVideo {
        margin-top: $marginMedium;
      }
      p {
        margin: 0;
        font-size: theme.$fontSizeBodySmall !important;
        line-height: theme.$fontLineHeightBodySmall !important;
      }
    }
    .link {
      margin: $linkMarginBottomDevice;
      &:not(:last-child) {
        @include theme.medium-device {
          margin-bottom: $rowGap;
          margin-right: $marginXSmall;
        }
      }

      @include theme.small-desktop {
        &.underCtaLink {
          margin-top: $marginMedium;
        }
      }
    }

    &.dark {
      .title,
      .text,
      .subtitle,
      .link {
        color: theme.$grey-secondary;
      }
      .link {
        &:hover {
          color: theme.$grey-secondary;
        }
      }
      .link {
        &:hover {
          color: theme.$grey-secondary;
        }
      }
    }

    &.light {
      .title,
      .text,
      .subtitle,
      .link {
        color: theme.$green-primary;
      }
      .link {
        &:hover {
          color: theme.$green-primary;
        }
      }
      .link {
        &:hover {
          color: theme.$green-primary;
        }
      }
    }
  }

  &.hasFluidPaddings {
    max-width: theme.fluid-size($maxWidthMin, $maxWidthMax, theme.$largeDevice, theme.$maxViewportWidth);
    padding-right: $marginMedium;
    &.hasImage {
      max-width: unset;
    }

    &.accordion {
      padding: $paddingMedium $marginMedium $paddingMedium 0;
    }

    &.hasVideo {
      @include theme.small-desktop {
        padding: $desktopVideoAccordionTopPadding theme.fluid-size(0px,$desktopVideoAccordionRightPadding,theme.$smallDesktop, theme.$maxViewportWidth) $desktopVideoAccordionBottomPadding 0;
        max-width: unset;
        gap: $desktopVideoAccordionGap;
      }
    }
  }

  .videoContainer {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: $desktopVideoContainerWidth;
  }

  .videoWrapper {
    position: relative;
    width: 100%;
    height: $desktopVideoWrapperHeight;
    border-radius: $desktopVideoWrapperBorderRadius;
    overflow: hidden;

    [data-track-action="click"] {
      right: 50%;
      bottom: 50%;
      transform: translate(50%,50%);
      padding: 0;
    }
  }

  .videoCaption {
    margin-top: $marginSmall;
    font-family: $amazonEmberMono;
    font-size: theme.$fontSizeLabel !important;
    line-height: theme.$fontLineHeightLabel !important;
    color: theme.$white;
  }
}

.cardHeader {
  color: theme.$green-primary;
  display: flex;
  column-gap: $columnGapLarge;
}

#listComponent {
  .title {
    margin-bottom: $marginSmall;

    @include theme.medium-device {
      margin-bottom: $marginMedium;
    }
  }
}

.imageContainer {
  block-size: theme.fluid-size($minHeight, $maxHeight, $deviceMaxView, theme.$maxViewportWidth);
  inline-size: theme.fluid-size($minWidth, $maxWidth, $deviceMaxView, theme.$maxViewportWidth);
  flex-shrink: 0;
  border-radius: theme.$border-radius-small;

  @include theme.large-device {
    border-radius: theme.$border-radius-medium;
  }
}

.image {
  @include theme.size(100%, 100%);
  object-fit: cover;
  border-radius: theme.$border-radius-small;

  @include theme.large-device {
    border-radius: theme.$border-radius-medium;
  }
}

.monospace {
  font-family: 'amazon-ember-mono';
}

.label {
  font-size: theme.$fontSizeLabel;
  line-height: theme.$fontLineHeightLabel;
  font-weight: 400;
  color: theme.$green-primary;
}

.cardCta {
  display: flex;
  min-width: max-content;
  align-items: center;
  align-self: flex-start;
  column-gap: $columnGapMedium;
  width: 100%;
  justify-content: space-between;

  @include theme.large-device {
    width: auto;
    justify-content: flex-start;
  }
}

.button {
  margin-left: auto;
}

.buttonLeft {
  margin-left: 0;
}
