@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$containerVerticalPadding: 24px;
$containerHorizontalPadding: 0px;

$dividerDashWidth: 8px;
$dividerDashHeight: 1px;

$smallDeviceVerticalPadding: 24px;
$largeDeviceVerticalPadding: 56px;

$taglineTopSpaceXSmall: 8px;
$taglineBottomSpaceXSmall: 12px;

$taglineTopSpaceSmallDevice: 8px;
$taglineTopSpaceLessThanLargeDevice: 32px;

$taglineBottomSpaceSmallDevice: 12px;
$taglineBottomSpaceLessThanLargeDevice: 32px;

$taglineTopSpaceLargeDevice: 0px;
$taglineBottomSpaceLargeDevice: 32px;

$copysSpacing: 32px;

$copyMaxWidthDesktop: 584px;

$copyMargins: theme.fluid-size(16px, 32px, theme.$minViewportWidth, theme.$maxViewportWidth);

.dynamicItem {
  position: relative;
  transition: opacity 0.4s;
  width: 90%;
  padding: $containerVerticalPadding $containerHorizontalPadding;
  background-position: top;
  background-size: $dividerDashWidth $dividerDashHeight;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, rgba(theme.$green-primary, 1) 33%, rgba(theme.$green-primary, 0) 0%);
  &.imageVariant {
    background-image: none;
    border-top: 1px solid rgba(theme.$green-primary, 0.3);
    max-width: unset;
  }

  @include theme.small-device {
    padding-top: theme.fluid-size(
      $smallDeviceVerticalPadding,
      $largeDeviceVerticalPadding,
      theme.$smallDevice,
      theme.$largeDevice
    );
    padding-bottom: theme.fluid-size(
      $smallDeviceVerticalPadding,
      $largeDeviceVerticalPadding,
      theme.$smallDevice,
      theme.$largeDevice
    );
  }

  @include theme.large-device {
    width: 100%;
    padding: $largeDeviceVerticalPadding 0;
    max-width: $copyMaxWidthDesktop;
    flex-direction: row;
    justify-content: flex-end;
  }

  // Last item doesn't have spacing to keep symmetry
  &:last-child:not(.imageVariant) {
    padding-bottom: 0;
  }
}

.disabled {
  opacity: 0.4;
}

.tagline {
  color: theme.$green-primary;
  margin: $taglineTopSpaceXSmall 0 $taglineBottomSpaceXSmall;

  @include theme.small-device {
    margin-top: theme.fluid-size(
      $taglineTopSpaceSmallDevice,
      $taglineTopSpaceLargeDevice,
      theme.$smallDevice,
      theme.$largeDevice
    );
    margin-bottom: theme.fluid-size(
      $taglineTopSpaceSmallDevice,
      $taglineTopSpaceLessThanLargeDevice,
      theme.$smallDevice,
      theme.$largeDevice
    );
  }

  @include theme.large-device {
    margin-top: $taglineTopSpaceLargeDevice;
    margin-bottom: $taglineBottomSpaceLargeDevice;
  }
}

.title {
  color: theme.$green-primary;
  margin-bottom: $copysSpacing;
}

.copy {
  color: theme.$green-primary;
  margin-bottom: $copysSpacing;
}

li a.externalLink,
li button.externalLink {
  margin-bottom: calc($copysSpacing / 2);
  color: theme.$green-primary;
}

.imageVariantBodyWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: theme.$green-primary;

  @include theme.small-desktop {
    flex-direction: row;
    gap: 72px;
  }
}

.imageVariantLine {
  border-top: 1px solid theme.$green-primary;
  opacity: 0.3;
  display: none;
}

.imageVariantTitle {
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  min-width: 208px;
}

.imageVariantBody {
  font-size: theme.fluid-size(14px, 16px, theme.$minViewportWidth, theme.$maxViewportWidth);
  line-height: theme.fluid-size(20px, 24px, theme.$minViewportWidth, theme.$maxViewportWidth);
}

.highlight {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-weight: bold !important;
  padding: 0;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: theme.$white;
  background-color: theme.$green-primary;
  border-radius: 100%;
  transition: background-color 0.25s;
  @include theme.size(20px);

  &::before {
    content: '';

    border-radius: 100%;
    padding: 24px;
    background-color: theme.$green-primary;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    transition: opacity 0.25s;
    opacity: 0;
  }

  &:hover::before {
    opacity: 0.1;
  }
}

.markdownText {
  blockquote {
    padding-left: $copyMargins;
    margin: $copyMargins 0;

    p {
      margin: 0;
    }
  }

  strong {
    font-weight: bold;
    display: block;
    padding-left: $copyMargins;
  }
}

.imageVariantMarkdown {
  p {
    font-size: theme.fluid-size(14px, 16px, theme.$minViewportWidth, theme.$maxViewportWidth) !important;
    line-height: theme.fluid-size(20px, 24px, theme.$minViewportWidth, theme.$maxViewportWidth) !important;
  }
}
