@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import './PlyrOverrides.module.scss';

$mobileVideoTextContainerGap: 32px;
$mobileVideoPlayCtaPadding: 20px 32px 20px 24px;

$desktopVideoTextContainerGap: 56px;
$desktopVideoPlayCtaPadding: 20px 40px 20px 32px;

$mobileVideoTextContainerPadding: 16px;
$dektopVideoTextContainerPadding: 32px;

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoPoster {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.videoTextContainer {
  position: absolute;
  bottom: $mobileVideoTextContainerPadding;
  right: 0;
  display: flex;
  padding: 0 $mobileVideoTextContainerPadding;
  flex-direction: column;
  gap: theme.fluid-size(
    $mobileVideoTextContainerGap,
    $desktopVideoTextContainerGap,
    theme.$minViewportWidth,
    theme.$maxViewportWidth
  );

  @include theme.medium-device {
    bottom: $dektopVideoTextContainerPadding;
    padding: 0 $dektopVideoTextContainerPadding;
  }

  &.hasLabel {
    right: unset;
  }
}

.videoTextHeader {
  color: theme.$white;
}

.videoPlayCta {
  width: fit-content;
  font-size: theme.$fontSizeCta;
  line-height: theme.$fontLineHeightCta;
  align-self: flex-end;

  &.hasLabel {
    padding: $mobileVideoPlayCtaPadding !important;
    align-self: flex-start;

    @include theme.small-desktop {
      padding: $desktopVideoPlayCtaPadding !important;
      height: 80px;
    }
  }
}

.videoPreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pauseButton {
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 100%;
  color: theme.$white !important;
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  bottom: 28px;
  border: solid 2px rgba(theme.$white, 0.5) !important;

  @include theme.medium-device {
    width: 56px;
    height: 56px;
    bottom: 32px;
    right: 32px;
  }
}
