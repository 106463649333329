@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$paddingSmallDevice: 4px;
$paddingMediumDevice: 12px;
$paddingSmallDesktop: 16px;
$paddingMediumDesktop: 28px;
$paddingLargeDevice: 24px;
$paddingLargeDesktop: 120px;
$border-radius: 32px;
$iconSize: 24px;
$heightSmall: 32px;
$widthSmall: 48px;
$heightMedium: 40px;
$widthMedium: 56px;
$heightLarge: 56px;
$widthLarge: 80px;
$negativeMargin: -9px;
$borderWidth: 125%;
$copyPaddingRight: 100px;
$maxWidthMax: 880px;
$maxWidthMin: 400px;

$cardMobileBorderRadius: 24px;
$cardDesktopBorderRadius: 32px;
$cardBottomPadding: 24px;
$cardHeaderPadding: 12px 12px 0 0;
$cardNumberMobileSize: 12px;
$cardNumberDesktopSize: 14px;
$cardNumberMobileTopPadding: 54px;
$cardNumberDesktopTopPadding: 80px;
$cardNumberBottomPadding: 8px;
$cardContentPadding: 24px;
$cardContentMaxWidth: 464px;
$cardTextTopPadding: 16px;

.accordion {
  --card-height: max-height;
  --content-height: 0;

  &:not(:first-child):not(.card) {
    border-block-start: 1px solid;
  }
  &:last-child:not(.card) {
    border-block-end: 1px solid;
  }

  &.light {
    border-color: rgba(theme.$green-primary, 0.4);

    .subtitle {
      color: theme.$green-primary;
    }
  }

  &.dark {
    border-color: rgba(theme.$green-secondary, 0.4);

    .subtitle {
      color: theme.$grey-secondary;
    }
  }

  &.card {
    position: relative;
    block-size: max-content;
    border-radius: $cardMobileBorderRadius;
    padding-block-end: $cardBottomPadding;

    & > *:not(.accordionHeader) {
      pointer-events: none;
    }

    &.light {
      background: theme.$grey-tertiary;

      .cardNumber {
        color: theme.$green-primary;
        font-family: 'amazonEmberMono' !important;
      }

      .accordionTitle {
        color: theme.$green-primary;
      }

      .accordionIcon{
        color: theme.$green-secondary;
      }

      &.isOpen {
        background: theme.$green-primary;

        .cardNumber {
          color: theme.$grey-secondary;
        }

        .accordionTitle {
          color: theme.$green-secondary;
        }

        .accordionToggle {
          color: theme.$green-primary;
          background: theme.$green-secondary;

          &.open {
            .accordionIcon{
              color: theme.$green-primary;
            }
          }
        }

        .accordionContent {
          color: theme.$grey-secondary;
        }
      }

      .accordionToggle {
        color: theme.$green-secondary;
        background: theme.$green-primary;
      }
    }

    .accordionHeader {
      position: absolute;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-end;
      padding: $cardHeaderPadding;
    }

    .cardNumber {
      font-family: var(--amazon-ember-mono);
      font-size: $cardNumberMobileSize;
      padding-block-start: $cardNumberMobileTopPadding;
      padding-block-end: $cardNumberBottomPadding;
      padding-inline-start: $cardContentPadding;
    }

    .accordionTitle {
      inline-size: 100%;
      max-inline-size: $cardContentMaxWidth;
      padding-inline-start: $cardContentPadding;
    }

    .accordionContent {
      margin-top: unset;
      inset-block-end: 0;
      padding-block-start: $cardTextTopPadding;
      padding-block-end: unset;
    }

    .content {
      height: var(--content-height);
      inline-size: 100%;
      max-inline-size: $cardContentMaxWidth;
      padding-inline-start: $cardContentPadding;
      padding-inline-end: $cardContentPadding;
    }

    @include theme.large-device {
      .accordionToggle {
        inline-size: $widthMedium;
        block-size: $heightMedium;
      }
    }

    @include theme.small-desktop {
      display: grid;
      grid-template-rows: 1fr auto auto;
      block-size: var(--card-height);
      border-radius: $cardDesktopBorderRadius;

      .cardNumber {
        align-self: end;
        font-size: $cardNumberDesktopSize;
        padding-block-start: $cardNumberDesktopTopPadding;
      }

      .accordionTitle {
        align-self: end;
      }
    }
  }

  &.hasFluidPaddings {
    .subtitle {
      @include theme.large-device {
        max-width: fluid-size($maxWidthMin, $maxWidthMax, theme.$maxViewportWidth, theme.$maxViewportWidth);
      }
    }

    .accordionContent {
      padding-right: fluid-size(
        $paddingLargeDevice,
        $copyPaddingRight,
        theme.$maxViewportWidth,
        theme.$maxViewportWidth
      );
    }

    .content {
      height: var(--content-height);
    }
  }

  .accordionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $paddingLargeDevice 0;
    width: 100%;
    text-align: start;

    .accordionTitle {
      font-size: theme.$fontSizeHeading05 !important;
      line-height: theme.$fontLineHeightHeading05 !important;
      margin: 0;

      &.dark {
        color: theme.$green-secondary;
      }
      &.light {
        color: theme.$green-primary;
      }
      &.main-banner {
        font-size: theme.$fontSizeSubtitle !important;
        line-height: theme.$fontLineHeightSubtitle !important;
        font-family: theme.$emberDisplayReg;
      }

      &.accordion-component:not(.light) {
        &.isOpen {
          color: theme.$grey-secondary;
        }
      }
    }
  }

  .accordionToggle {
    display: flex;
    inline-size: $widthSmall;
    block-size: $heightSmall;
    padding: $paddingSmallDevice $paddingMediumDevice;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: $border-radius;
    border: 1px solid;

    .accordionIcon {
      @include theme.size($iconSize);
    }

    &.dark {
      border-color: theme.$green-secondary;
      &.open {
        background: theme.$green-secondary;
        .accordionIcon {
          color: theme.$green-primary;
        }
      }
      &.closed {
        color: theme.$green-secondary;
      }
    }

    &.light {
      border-color: theme.$green-primary;
      &.open {
        background: theme.$green-primary;
        .accordionIcon {
          color: theme.$green-secondary;
        }
      }
      &.closed {
        color: theme.$green-primary;
      }
    }

    @include theme.large-device {
      inline-size: $widthLarge;
      block-size: $heightLarge;
      padding: $paddingSmallDesktop $paddingMediumDesktop;
    }
  }

  .content {
    height: var(--content-height);
    overflow: hidden;
    transition-property: height;
    transition-delay: 0.01s;
    transition-timing-function: linear;
    transition-duration: 0.2s;

    @include theme.large-device {
      padding-inline-end: $paddingLargeDesktop;
    }

    @include theme.small-desktop {
      &.hasVideo {
        padding-inline-end: 0;
      }
    }
  }

  .accordionContent {
    opacity: theme.$asxCopyOpacity;
    margin-bottom: 0;
    padding-block-end: $paddingLargeDevice;

    &.dark {
      color: theme.$grey-secondary;
    }
    &.light {
      color: theme.$green-primary;
    }
    @include theme.large-device {
      margin-top: $negativeMargin;
    }
  }

  .listUnderline {
    width: $borderWidth;
    height: 1px;
    &.light {
      background-color: rgba(theme.$green-primary, 0.4);
    }
    &.dark {
      background-color: rgba(theme.$green-secondary, 0.4);
    }
  }
}