@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$modalBorderRadius: 32px;
$closePadding: 8px;
$closeSize: 24px;

$mobileHeaderPadding: 8px 8px 0 8px;
$mobileModalMaxHeight: 680px;
$mobileBodyPadding: 16px 12px 16px 16px;
$mobileSubtitleMarginTop: 12px;

$desktopHeaderPadding: 16px;
$desktopModalMaxWidth: 864px;
$desktopBodyPadding: 16px 8px 16px 16px;
$desktopSubtitleMarginTop: 32px;

$thumbnailMaxWidth: 88px;
$thumbnailBorderRadius: 16px;

// Ensures that the modal sits on top
$modal-overlay-z-index-desktop: 3;
$modal-overlay-z-index: 99999;

.header {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  @include theme.small-desktop {
    justify-content: flex-end;
    margin-bottom: -8px;
  }
}

.infoIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: theme.$white;
  background-color: theme.$green-primary;
  border-radius: 100%;
  @include theme.size(20px);
  svg {
    @include theme.size(20px);
  }
}

.iconButton {
  @include theme.size(24px);
  padding: 0;
}

.closeIcon {
  @include theme.size(100%);
  color: theme.$green-primary;
}

.bodyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.thumbnail {
  aspect-ratio: 1 / 1;
  border-radius: $thumbnailBorderRadius;
  max-width: $thumbnailMaxWidth;
}

.body {
  font-size: theme.$fontSizeBodySmall !important;
  line-height: theme.$fontLineHeightBodySmall !important;
  margin-bottom: 12px !important;
}

.content.dark {
  .body {
    color: theme.$green-primary;
  }
}

// https://katal.amazon.dev/design-system/components/modal/?tab-info=development&tab-props=style-api
kat-modal.TooltipModal {
  z-index: 99999;
  visibility: hidden !important;
  max-width: 0 !important;
  font-family:
    #{website.$amazonEmberDisplay},
    sans-serif;

  --overlay-background: #{rgba(theme.$slate-dark, 0.7)};
  --header-padding: #{$mobileHeaderPadding};
  --body-padding: #{$mobileBodyPadding};
  --footer-padding: 0;
  --border-radius: #{$modalBorderRadius};
  --body-border-radius: #{$modalBorderRadius};

  --background: #{theme.$grey-secondary};
  --header-background: #{theme.$grey-secondary};
  --body-background: #{theme.$white};

  --header-border-width: 0;
  --footer-border-width: 0;
  --body-border-width: 0;

  --close-background: transparent;
  --close-border-radius: #{$modalBorderRadius};
  --close-color: #{theme.$green-primary};
  --close-padding: #{$closePadding};
  --close-size: #{$closeSize};
  --z-index: #{$modal-overlay-z-index};

  @include theme.small-desktop {
    z-index: 3;
    --z-index: #{$modal-overlay-z-index-desktop};

    position: relative;
    --header-padding: #{$desktopHeaderPadding};
    --body-padding: #{$desktopBodyPadding};
    --overlay-background: transparent;
  }
}

kat-modal.TooltipModal.visible {
  visibility: visible !important;
  max-width: unset !important;
  display: unset !important;
}
