@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

.highlight {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-weight: bold !important;
  padding: 0;
  z-index: 2; // Ensure buttons are rendered above other page content
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;

  > svg {
    pointer-events: none;
  }
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: theme.$white;
  background-color: theme.$green-primary;
  border-radius: 100%;
  transition: background-color 0.25s;
  @include theme.size(20px);
  pointer-events: none;

  svg {
    @include theme.size(20px);
  }

  &::before {
    content: '';

    border-radius: 100%;
    padding: 24px;
    background-color: theme.$green-primary;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    transition: opacity 0.25s;
    opacity: 0;
  }

  &:hover::before {
    opacity: 0.1;
  }

  &.dark {
    color: theme.$green-primary;
    background-color: theme.$white;

    &.isOpen {
      background-color: theme.$green-secondary;
    }

    &::before {
      background-color: theme.$white;
    }
  }
}
