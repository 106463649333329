@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$paddingRightDevice: 8vw;
$maxWidthDesktop: 38vw;
$maxWidthXLargeDesktop: 28vw;
$maxWidthXXLargeDesktop: 23vw;
$maxWidthXXXLargeDesktop: 20vw;
$maxWidthDevice: 88vw;
$marginBottomDevice: 24px;
$marginBottomDesktop: 125px;
$marginTopDevice: 218px;
$marginTopDesktop: 71px;
$paddingDevice: 25px;
$marginBottomWrapper: 40px;
$gap: 14px;
$borderColor: rgba(theme.$grey-secondary, 0.5);
$headingPaddingbottom: 32px;
$headingLetterSpacing: -0.02em;

$minViewportWidth: 350px;

$sliderMarginBottom: 120px;

div.textComponent {
  margin-bottom: $marginBottomDevice;
  margin-top: $marginTopDevice;
  padding: 0 $paddingDevice;
  align-items: center;
  gap: $gap;
  justify-content: inherit;
  color: theme.$grey-secondary !important;

  div{
    color: theme.$grey-secondary !important;
  }

  @include theme.small-device {
    align-items: flex-start;
  }

  @include theme.medium-device {
    margin-top: $marginTopDesktop;
    gap: none;
    justify-content: space-around;
  }

  @include theme.small-desktop {
    margin-bottom: $marginBottomDesktop;
    padding: 0;
  }
}

.textComponentHeading {
  border-bottom: 1px dashed;
  border-bottom-color: $borderColor;
  padding-bottom: $headingPaddingbottom;
  width: 100%;
  font-size: theme.fluid-size(32px, 112px, $minViewportWidth, theme.$maxViewportWidth) !important;
  text-align: center;
  letter-spacing: $headingLetterSpacing !important;

  @include theme.small-device {
    border-bottom: none;
    padding-bottom: 0;
  }

  @include theme.small-desktop {
    text-align: start;
  }
}

.textComponentContent {
  @include theme.small-desktop {
    flex: unset !important;
  }
}

.textComponentCopy {
  padding-right: unset;
  max-width: $maxWidthDevice;

  @include theme.small-desktop {
    padding-right: $paddingRightDevice;
    max-width: $maxWidthDesktop;
  }

  @media screen and (min-width: 2700px) {
    max-width: $maxWidthXLargeDesktop;
  }

  @media screen and (min-width: 3700px) {
    max-width: $maxWidthXXLargeDesktop;
  }

  @media screen and (min-width: 4700px) {
    max-width: $maxWidthXXXLargeDesktop;
  }
}

.slider {
  margin-bottom: $sliderMarginBottom;

  @include theme.small-desktop {
    margin-bottom: 0;
  }
}
