@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$dividerHeight: 10px;
$dividerBorderRadius: 8px 0 0 0;
$faqItemAnswerListLeftMargin: 20px;

$mobileFaqItemContentPadding: 8px 0 0 0;
$mobileFaqItemQuestionMargin: 12px 0 16px 0;
$mobileFaqItemAnswerGap: 22px;
$mobileFaqItemAnswerLinkWrapperTopMargin: 22px;

$desktopFaqItemContentPadding: 20px 7.5vw 24px 0;
$desktopFaqItemIndexPadding: 12px 0;
$desktopFaqItemIndexWidth: 6.5vw;
$desktopFaqItemQuestionWidth: 30vw;
$desktopFaqItemQuestionMargin: 0 9.5vw 0 24px;
$desktopFaqItemAnswerGap: 32px;
$desktopFaqItemAnswerLinkWrapperTopMargin: 32px;

.divider {
  display: block;
  height: $dividerHeight;
  border-radius: $dividerBorderRadius;
  border-top: 1px solid rgba(theme.$green-primary, 0.3);
  border-left: 1px solid rgba(theme.$green-primary, 0.3);
}

.faqItemContent {
  display: flex;
  flex-direction: column;
  padding: $mobileFaqItemContentPadding;
  @include theme.small-desktop {
    padding: $desktopFaqItemContentPadding;
    flex-direction: row;
  }
}

.faqItemIndex {
  color: theme.$green-primary;
  opacity: 0.85;
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;

  @include theme.small-desktop {
    padding: $desktopFaqItemIndexPadding;
    width: $desktopFaqItemIndexWidth;
    flex-shrink: 0;
  }
}

.question {
  color: theme.$green-primary;
  margin: $mobileFaqItemQuestionMargin !important;
  @include theme.small-desktop {
    width: $desktopFaqItemQuestionWidth;
    margin: $desktopFaqItemQuestionMargin !important;
    flex-shrink: 0;
  }
}

.answer {
  color: theme.$green-primary;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  display: flex;
  flex-direction: column;
  gap: $mobileFaqItemAnswerGap;
  
  // Remove any margins from our markdown paragraphs
  p {
    margin: 0 !important;
    opacity: 0.85;
  }

  // Add styles to our markdown lists and list items
  ol {
    list-style: decimal !important;
    margin-left: $faqItemAnswerListLeftMargin !important;

    li {
      color: theme.$green-primary !important;
      opacity: 0.85;
    }
  }
  
  @include theme.small-desktop {
    gap: $desktopFaqItemAnswerGap;
  }
}

.answerLinkWrapper {
  margin-top: $mobileFaqItemAnswerLinkWrapperTopMargin;

  a {
    color: theme.$green-primary !important;
  }

  @include theme.small-desktop {
    margin-top: $desktopFaqItemAnswerLinkWrapperTopMargin;
  }
}