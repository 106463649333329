// ASH Theming
@import '~@amzn/sss-website-theme/dist/theme/index.module.scss';

@font-face {
  font-family: 'amazonEmberMono';
  src:
    url('~@amzn/sss-website-theme/dist/fonts/amazon-ember-mono/AmazonEmberMono_W_Rg.woff2') format('woff2'),
    url('~@amzn/sss-website-theme/dist/fonts/amazon-ember-mono/AmazonEmberMono_W_Rg.woff') format('woff');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$amazonEmberMono: 'amazonEmberMono';
$amazonEmberDisplay: 'Amazon Ember';

$largeDeviceRightPadding: 262px;
$paddingDevice: 24px;

// Z Index
$zLayout: outroComponent, lastComponent, default, dynamicListImage, anchorLinks, hero, backToTop;

#root {
  margin: 0;
  height: 100%;
  background-color: #{$grey-secondary};
}

html,
body {
  margin: 0;
  height: 100%;
  background-color: #{$grey-secondary};
  scroll-behavior: smooth;
}

// Utility Classes
.padded {
  padding: 20px;
}

.accesibilityHelper {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.largeDeviceRightPadding {
  @include large-device {
    padding-right: fluid-size($paddingDevice, $largeDeviceRightPadding, $largeDevice, $maxViewportWidth) !important;
  }
}
