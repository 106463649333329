@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$desktopTextComponentHeadingMaxWidth: 581px;
$desktopTextComponentContentMaxWidth: 736px;

$mediumDeviceSpacing: 0 64px;
$mobileDeviceSpacing: 0 25px;

.tagline {
  color: theme.$green-primary;
  font-family: website.$amazonEmberMono;
  margin-bottom: 32px;
}

.textComponent {
  white-space: pre-line;

  @include theme.small-desktop {
    justify-content: space-between;
  }
}

.textComponentHeading {
  @include theme.small-desktop {
    max-width: $desktopTextComponentHeadingMaxWidth;
  }
}

.textComponentContent {
  @include theme.small-desktop {
    max-width: $desktopTextComponentContentMaxWidth;
  }
}

.segmentsTitleWrapper {
  padding-bottom: theme.fluid-size(48px, 112px, theme.$minViewportWidth, theme.$maxViewportWidth) !important;
  padding-top: theme.fluid-size(2px, 80px, theme.$minViewportWidth, theme.$maxViewportWidth) !important;
}

.segmentsTitle {
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  text-align: center;
  color: theme.$green-primary;
  max-width: 888px;
  margin: 0 auto;
  width: 100%;

  @include theme.large-device {
    font-size: theme.$fontSizeHeading05 !important;
    line-height: theme.$fontLineHeightHeading05 !important;
    width: 46.25vw;
  }
}

// serves to restrict the sticky navigation tabs to a certain section, and not continue showing outside it
.navigationAnchorSection{
  position: relative;
}

.segmentsSection {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0 !important;
}

.restorationTabSection {
  padding: $mobileDeviceSpacing;

  @include theme.medium-device {
    padding: $mediumDeviceSpacing;
  }
}

