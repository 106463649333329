@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

.modalFooter {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(theme.$green-primary, 0.15);
  padding: 20px 0;
  justify-content: space-between;
  color: theme.$green-primary;
}

.linkWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: 1px solid theme.$green-primary;
  }
  p,
  span {
    color: theme.$green-primary !important;
    font-size: theme.$fontSizeBody;
    line-height: theme.$fontLineHeightBody;
  }
}

.footerIconWrapper {
  @include theme.size(56px);
  background: theme.$white;
  padding: 16px;
  border-radius: 32px;
}

.footerIcon {
  @include theme.size(24px);
  background: theme.$green-primary;
  color: theme.$white;
  border-radius: 32px;

  &.invertedColors {
    background: theme.$white;
    color: theme.$green-primary;
  }

  svg {
    @include theme.size(24px);
  }
}

.footerParagraph {
  margin: 0 !important;
  letter-spacing: -0.2px;
  width: 100%;
}
