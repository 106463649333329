@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

.modalImageContainer {
  color: theme.$green-primary;
  padding-right: 12px;
  h4,
  p {
    margin: 0;
  }
}
.tagline {
  font-family: website.$amazonEmberMono;
  margin-top: theme.fluid-size(22px, 44px, theme.$minViewportWidth, theme.$maxViewportWidth);
}

.imageWrapper {
  position: absolute;
  width: calc(100% - 8px);
  height: theme.fluid-size(219px, 478px, theme.$minViewportWidth, theme.$maxViewportWidth);
  border-radius: 28px;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  &.isSmallVariant {
    height: theme.fluid-size(216px, 344px, theme.$minViewportWidth, theme.$maxViewportWidth);
  }

  @include theme.medium-device {
    border-radius: 24px;
    top: 8px;
    width: calc(100% - 16px);
  }
}

.taglinesWrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  @include theme.medium-device {
    bottom: unset;
    top: 10px;
    justify-items: start;
    left: 12px;
  }
}
.imageTagline {
  font-family: website.$amazonEmberMono;
  background-color: theme.$green-primary !important;
  color: theme.$green-secondary;
  padding: 6px 8px !important;
  @include theme.medium-device {
    padding: 10px 12px !important;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 28px;
  position: absolute;

  @include theme.medium-device {
    border-radius: 24px;
  }
}

.copyWrapper {
  padding: 0 theme.fluid-size(24px, 135px, theme.$minViewportWidth, theme.$maxViewportWidth) 0
    theme.fluid-size(24px, 50px, theme.$minViewportWidth, theme.$maxViewportWidth);
  margin-top: theme.fluid-size(243px, 506px, theme.$minViewportWidth, theme.$maxViewportWidth);
  display: flex;
  flex-direction: column;
  gap: theme.fluid-size(12px, 32px, theme.$minViewportWidth, theme.$maxViewportWidth);

  &.isSmallVariant {
    margin-top: theme.fluid-size(240px, 372px, theme.$minViewportWidth, theme.$maxViewportWidth);
  }
}
