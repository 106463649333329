@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$modalBorderRadius: 32px;
$closePadding: 20px 35px;
$closeSize: 12px;

$mobileHeaderPadding: 8px 8px 0 8px;
$mobileModalMaxHeight: 680px;
$mobileBodyPadding: 0 24px;
$mobileSubtitleMarginTop: 12px;

$desktopHeaderPadding: 16px;
$desktopModalMaxWidth: 864px;
$desktopBodyPadding: 0 204px 0 52px;
$desktopSubtitleMarginTop: 32px;

$formDesktopFooterPadding: 8px;
$formDesktopMaxHeight: 735px;
$formDesktopMaxWidth: 732px;

// Ensures that the modal sits on top of
$modal-overlay-z-index: 99999;

$tabsTitlePadding: 20px 0 0 16px;

// https://katal.amazon.dev/design-system/components/modal/?tab-info=development&tab-props=style-api
kat-modal {
  font-family:
    #{$amazonEmberDisplay},
    sans-serif;

  --overlay-background: #{rgba($slate-dark, 0.7)};

  --background: #{$grey-secondary};
  --header-background: #{$grey-secondary};
  --body-background: #{$grey-secondary};
  --footer-background: #{$grey-secondary};

  --border-radius: #{$modalBorderRadius};
  --header-border-radius: #{$modalBorderRadius} #{$modalBorderRadius} 0 0;
  --footer-border-radius: 0 0 #{$modalBorderRadius} #{$modalBorderRadius};

  --close-background: #{$green-primary};
  --close-border-radius: #{$modalBorderRadius};
  --close-color: #{$green-secondary};
  --close-padding: #{$closePadding};
  --close-size: #{$closeSize};
  --z-index: #{$modal-overlay-z-index};

  --header-border-width: 0;
  --footer-border-width: 0;
  --body-border-width: 0;
  --footer-mobile-width: 100%;

  --header-padding: #{$mobileHeaderPadding};
  --body-padding: #{$mobileBodyPadding};

  @include theme.small-desktop {
    --header-padding: #{$desktopHeaderPadding};
  }
}

kat-modal.info {
  --max-height: #{$mobileModalMaxHeight};
  --max-width: unset;
  --height: 100%;
  --width: 100%;
  @include theme.medium-device {
    // Katal has a breakpoint at 720px where it applies a max-width, we want to avoid that until 768px to be able to apply
    // styles that are consistent with our queries (we don't have a 720px query), so when we reach the medium device breakpoint we will revert the max-width
    // to the one that Katal applies
    --max-height: unset;
    --max-width: #{$desktopModalMaxWidth};
  }

  @include theme.small-desktop {
    --body-padding: #{$desktopBodyPadding};
  }
}

.info {
  color: #{$green-primary};

  .Title {
    color: #{$green-primary};
    font-size: #{$fontSizeHeading03} !important;
    line-height: #{$fontLineHeightHeading03} !important;
  }

  .Subtitle {
    font-size: #{theme.$fontSizeBody} !important;
    line-height: #{theme.$fontLineHeightBody} !important;
    margin-top: $mobileSubtitleMarginTop;
    color: #{$green-primary};
    opacity: #{$asxCopyOpacity};
    @include theme.small-desktop {
      margin-top: $desktopSubtitleMarginTop;
    }
  }
}

kat-modal.form {
  --footer-padding: 16px !important;
  --height: 100%;
  --max-height: 99vh;
  --max-width: 97vw;
  --body-padding: 0 16px;
  --body-overflow: hidden auto;

  @include theme.medium-device {
    --width: 100%;
    --height: 100%;
    --body-padding: 0 24px;
    --footer-padding: #{$formDesktopFooterPadding};
    --max-height: #{$formDesktopMaxHeight};
    --max-width: #{$formDesktopMaxWidth};
  }
}
kat-modal.stats {
  --body-padding: unset;
  --footer-padding: 0 16px 0;
  --header-padding: 8px 8px;

  --height: 100%;
  --width: 100%;
  @include theme.medium-device {
    --footer-padding: 0 40px 20px;
    --max-height: unset;
    --header-padding: 16px 16px;
    --max-width: #{$desktopModalMaxWidth};
  }
}
kat-modal.quote {
  --body-padding: unset;
  --footer-padding: 20px 16px 0;
  --height: 100%;
  --width: 100%;
  --header-padding: 8px 8px;

  @include theme.medium-device {
    --footer-padding: 20px 40px;
    --max-height: unset;
    --max-width: #{$desktopModalMaxWidth};
    --header-padding: 16px 16px;
  }
}

kat-modal.image {
  --body-padding: 0;
  --footer-padding: 20px 16px 0;
  --body-border-radius: 32px;
  --height: 100%;
  --width: 100%;
  --header-padding: 8px 8px;

  &.invertedColor {
    --close-background: #{$white};
    --close-color: #{$green-primary};
  }

  @include theme.medium-device {
    --body-padding: 0;
    --footer-padding: 20px 40px;
    --max-height: unset;
    --max-width: #{$desktopModalMaxWidth};
    --header-padding: 16px 16px;
  }
}

.form {
  color: #{$green-primary};

  @include theme.small-device {
    height: #{$formDesktopMaxHeight};
  }

  @include theme.medium-device {
    width: #{$formDesktopMaxWidth};
    min-width: unset;
  }

  .Title {
    color: #{$green-primary};
    font-size: #{$fontSizeHeading05} !important;
    line-height: #{$fontLineHeightHeading05} !important;
  }

  .Subtitle {
    font-size: #{theme.$fontSizeBody} !important;
    line-height: #{theme.$fontLineHeightBody} !important;
    margin-bottom: 8px;
    color: #{$green-primary};
    opacity: #{$asxCopyOpacity};
  }
}

.form * {
  overflow: hidden;
  text-overflow: ellipsis;
}

kat-modal.tabs {
  --footer-padding: 16px;
  --footer-border-radius: 0;
  --max-width: unset;
}

.tabs {
  .Title {
    padding: $tabsTitlePadding;
    font-family: $amazonEmberMono !important;
    font-size: theme.$fontSizeLabel !important;
    line-height: theme.$fontLineHeightLabel !important;
    color: theme.$green-primary;
  }
}
