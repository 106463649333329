@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$gradientBackgroundColor: #090c11;

$firstGradientWidth: 50%;
$firstGradientHeight: 100%;

$secondGradientSize: 31%;

$mobileAssetBorderRadius: 24px;

$mobileHeadingPaddingLeft: 24px;
$mobileHeadingPaddingTop: 24px;

$mobileHeroAssetTopMargin: 48px;
$mobileHeroAssetLateralMargin: 8px;

$headingMaxWidth: 90%;

$mediumDeviceHeadingMaxWidth: 33%;

$desktopHeadingPaddingLeft: 80px;
$desktopHeadingPaddingTop: 88px;
$desktopHeroAssetTopMargin: 40px;

.heroAsset {
  position: relative;
  overflow: hidden;
  border-radius: $mobileAssetBorderRadius;
  margin: $mobileHeroAssetTopMargin $mobileHeroAssetLateralMargin;

  @include theme.small-desktop {
    border-radius: theme.$border-radius-medium;
    margin-top: $desktopHeroAssetTopMargin;
  }

  // First gradient
  &::before {
    content: '';
    display: block;
    position: absolute;

    background-color: rgba($gradientBackgroundColor, 0.5);
    
    @include theme.size(100%, 100%);

    @include theme.medium-device {
      @include theme.size($firstGradientWidth, $firstGradientHeight);

      background: linear-gradient(
        90deg,
        rgba($gradientBackgroundColor, 0.7) 0%,
        rgba($gradientBackgroundColor, 0.5) 60%,
        rgba($gradientBackgroundColor, 0) 100%
      );
    }
  }

  // Second gradient
  &::after {
    visibility: hidden;

    @include theme.medium-device {
      content: '';
      visibility: visible;
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;

      aspect-ratio: 1 / 1;
      width: $secondGradientSize;
      height: auto;

      background: linear-gradient(
        135deg,
        rgba($gradientBackgroundColor, 0) 50%,
        rgba($gradientBackgroundColor, 0.7) 100%
      );
    }
  }
}

.heading {
  color: theme.$white;
  position: absolute;
  padding-left: $mobileHeadingPaddingLeft;
  padding-top: $mobileHeadingPaddingLeft;
  font-size: theme.$fontSizeHeading02 !important;
  line-height: theme.$fontLineHeightHeading02 !important;
  letter-spacing: -0.015em !important;
  max-width: $headingMaxWidth;

  @include theme.medium-device {
    max-width: $mediumDeviceHeadingMaxWidth;
  }

  @include theme.small-desktop {
    padding-left: $desktopHeadingPaddingLeft;
    padding-top: $desktopHeadingPaddingLeft;
  }
}

.image {
  aspect-ratio: 1.59 / 1;
  width: 100%;

  @include theme.medium-device {
    aspect-ratio: 2.22 / 1;
  }
  @include theme.large-device {
    aspect-ratio: 2.81 / 1;
  }
}
