@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

.videoPlayerWrapper{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  video{
    border-radius: 24px;
    width: 95%;
    aspect-ratio: 16 / 9;
  }
}

kat-modal.videoPlayerModal{
  --width: 100%;
  --height: 100%;
  --max-height: unset;
  --max-width: unset;
  --body-padding: 0;
  --footer-padding: 0;
  --header-background: #{rgba(theme.$slate-dark, 0.75)};
  --body-background: #{rgba(theme.$slate-dark, 0.75)};
  --footer-background: #{rgba(theme.$slate-dark, 0.75)};
  --border-radius: 0;
  --header-border-radius: 0;
}

.componentWrapper {
  max-width: theme.$maxViewportWidth;
  color: theme.$green-primary;
  margin: 0 auto;

  @include theme.large-device {
    margin-bottom: theme.fluid-size(100px, 200px, theme.$largeDevice, theme.$maxViewportWidth);
    margin-top: 80px;
  }
}
.featuredBanner {
  display: flex;
  flex-direction: column-reverse;
  padding: 40px 25px 80px;
  gap: 48px;
  position: relative;
  height: 100%;
  @include theme.medium-device {
    flex-direction: row;
    // padding: 0;
    align-items: center;
  }

  @include theme.small-desktop {
    padding: 0;

    align-items: unset;
  }
}

.videoSection {
  @include theme.large-device {
    padding-top: theme.fluid-size(1px, 84px, theme.$largeDevice, theme.$maxViewportWidth);
    width: 50%;
  }
}

.videoWrapper {
  // height: theme.fluid-size(460px, 800px, theme.$minViewportWidth, theme.$maxViewportWidth);
  aspect-ratio: 364/460;

  margin: 0 auto;
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  max-width: theme.fluid-size(400px, 676px, theme.$largeDevice, theme.$maxViewportWidth);

  &.hasQuote {
    &::before {
      @include theme.pseudo;

      height: theme.fluid-size(256px, 456px, theme.$minViewportWidth, theme.$maxViewportWidth);
      width: 100%;
      bottom: 0;
      border-radius: 0px 0px 24px 24px;
      background: linear-gradient(0deg, rgba(9, 12, 17, 0.7) 0%, rgba(9, 12, 17, 0.6) 56.33%, rgba(9, 12, 17, 0) 100%);
      z-index: 1;
    }
  }

  @include theme.large-device {
    aspect-ratio: 676/800;

    &::before {
      background: linear-gradient(0deg, rgba(9, 12, 17, 0.7) 0%, rgba(9, 12, 17, 0.6) 56.33%, rgba(9, 12, 17, 0) 100%);
    }
  }
}

.quote {
  margin: 0 !important;
  color: theme.$white;
  position: absolute;
  bottom: 96px;
  width: 100%;
  padding: 0 16px;
  z-index: 1;

  @include theme.large-device {
    bottom: 136px;
    padding: 0 32px;
    max-width: 486px;
    max-width: theme.fluid-size(430px, 546px, theme.$largeDevice, theme.$maxViewportWidth);
  }
}

.titleSection {
  position: relative;
  @include theme.large-device {
    padding-top: theme.fluid-size(1px, 224px, theme.$largeDevice, theme.$maxViewportWidth);
    height: 100%;
  }
}

.title {
  color: theme.$green-primary;
  margin-bottom: 16px;
  h2 {
    letter-spacing: -1.32px !important;
    margin: 0 !important;
  }

  @include theme.large-device {
    margin-bottom: 40px;
  }
}

.copy {
  margin-bottom: 24px !important;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  max-width: 475px;
  opacity: theme.$asxCopyOpacity;
  letter-spacing: -0.16px;
  @include theme.large-device {
    max-width: 520px;
  }

  .inTextLink {
    margin-right: 5px;
    padding: 0;
    font-weight: 700;

    span {
      font-size: theme.$fontSizeBody;
      line-height: theme.$fontLineHeightBody;
    }
    &:hover {
      .inTextLinkIcon {
        background: theme.$green-secondary;
        color: theme.$green-primary;
      }
    }
  }

  .inTextLinkIcon {
    background: theme.$white;
    border-radius: 50%;
  }
}
.link {
  padding: 0;
  font-weight: 700;
}

.imageWrapper {
  width: theme.fluid-size(152px, 304px, theme.$smallDesktop, theme.$maxViewportWidth);
  height: theme.fluid-size(180px, 360px, theme.$smallDesktop, theme.$maxViewportWidth);
  position: absolute;
  bottom: -25vw;
  right: 4vw;
  z-index: 1;
  @include theme.medium-desktop {
    right: 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  opacity: 0.9;
}
