@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$smallDesktopSpacing: 0 60px;
$gridSmallDesktopColumnGap: 24px;

$mobileCardBorderRadius: 24px;
$desktopCardBorderRadius: 32px;
$desktopDescriptionMaxWidth: 584px;

$mobileSpacing: 0 25px;
$mediumDeviceSpacing: 0 64px;

$taglineMarginBottom: 16px;
$headingMarginBottom: 64px;

// Meassured in vw so it is relative to the screen width
$cardMaxHeight: 50vw;

$tabletPortraitBreakpoint: 650px;

.bentoCards {
  position: relative;
  padding: $mobileSpacing;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: theme.$green-primary;
  background-color: theme.$grey-secondary;

  .bentoCardsWrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
  }

  .textContent {
    width: 100%;
    position: relative;
  }

  .description {
    white-space: pre-line;

    @include theme.small-desktop {
      max-width: $desktopDescriptionMaxWidth;
    }
  }

  .highlight {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    font-weight: bold !important;
    padding: 0;
    font-size: theme.$fontSizeBody !important;
    line-height: theme.$fontLineHeightBody !important;
  }

  .slider {
    kat-carousel-item {
      section {
        height: unset !important;
        aspect-ratio: 0.82 / 1;
      }
    }
  }

  .imageCard {
    border-radius: $mobileCardBorderRadius;
    width: 100% !important;

    @include theme.small-desktop {
      border-radius: $desktopCardBorderRadius;
    }
  }

  .card {
    aspect-ratio: 0.82 / 1;
    width: 100%;

    @media (min-width: $tabletPortraitBreakpoint) {
      height: 100%;
      max-width: 35vw;
      width: auto;
      flex: 1;
    }

    @include theme.small-desktop {
      aspect-ratio: 0.9 / 1;
      height: auto;
      width: auto;
      border-radius: $desktopCardBorderRadius;
    }
    
    .content {
      margin-top: auto;
    }
    
    .heading {
      color: theme.$green-secondary;
      font-size: theme.$fontSizeHeading02 !important;
      line-height: theme.$fontLineHeightHeading02 !important;
      margin-bottom: 10px;

      @include theme.small-desktop {
        aspect-ratio: unset;
        margin-bottom: 16px;
      }

      @include theme.large-desktop {
        margin-bottom: 32px;
      }
    }

    .description {
      color: theme.$grey-secondary;
      opacity: 0.85;
      font-size: theme.$fontSizeBody !important;
      line-height: theme.$fontLineHeightBody !important;
      margin: 0px !important;
    }
  }

  .bentoCardContainer {
    justify-content: space-between;
  }

  .titleCardContainer {
    max-height: $cardMaxHeight;
    overflow-y: hidden;
  }

  .tagline {
    font-family: website.$amazonEmberMono !important;
    margin-bottom: $taglineMarginBottom;
  }

  .firstTagline {
    background-color: rgba(theme.$white, 0.05);
    color: theme.$green-secondary;
  }

  .secondTagline {
    color: theme.$green-primary;
  }

  .heading {
    margin-bottom: $headingMarginBottom;
  }

  .cardHeading {
    color: theme.$green-secondary;
  }

  h4.cardHeading {
    @include theme.small-desktop {
      font-size: theme.fluid-size(26px, 42px, theme.$smallDesktop, theme.$maxViewportWidth) !important;
      line-height: theme.fluid-size(30px, 44px, theme.$smallDesktop, theme.$maxViewportWidth) !important;
    }
  }

  h5.cardHeading {
    @include theme.small-desktop {
      font-size: theme.fluid-size(22px, 32px, theme.$smallDesktop, theme.$maxViewportWidth) !important;
      line-height: theme.fluid-size(34px, 40px, theme.$smallDesktop, theme.$maxViewportWidth) !important;
    }
  }

  .gridElement {
    @include theme.small-desktop {
      // First element
      &:nth-child(1) {
        grid-area: 1 / 1 / auto / 6;
      }

      // Second element
      &:nth-child(2) {
        margin-top: 70%;
        grid-area: 1 / 7 / auto / 10;
      }

      // Third element
      &:nth-child(3) {
        margin-top: 30%;
        grid-area: 1 / 10 / auto / 13;
      }
    }
  }

  @include theme.medium-device {
    padding: $mediumDeviceSpacing;
  }

  @include theme.small-desktop {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: $gridSmallDesktopColumnGap;
  }
}

:export {
  tabletPortraitBreakpoint: $tabletPortraitBreakpoint;
}
