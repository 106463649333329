@use "~@amzn/sss-website-theme/dist/theme/index.module.scss" as theme;
@import 'src/index.scss';

$mobileBorderRadius: 24px;
$desktopBorderRadius: 40px;

$lateralPillsHorizontalMarginXSmall: 30px;
$lateralPillsHorizontalMarginSmall: theme.fluid-size(16px, 192px, theme.$smallDevice, theme.$largeDevice);
$lateralPillsHorizontalMarginLarge: theme.fluid-size(1px, 17px, theme.$largeDevice, theme.$smallDesktop);
$lateralPillsHorizontalMarginSmallDesktop: theme.fluid-size(8px, 48px, theme.$smallDesktop, 1920px);

$lateralPillsBottomPaddingSmall: 192px;
$lateralPillsBottomPaddingSmallDesktop: 218px;

// Just to enforce horizontal symmetry
$lateralPillsWidth: 12vw;
$lateralPillsMaxWidth: 160px;

$pauseCtaBottomDevicePosition: 32px;
$pauseCtaRightDevicePosition: 32px;

$pauseCtaBottomDesktopPosition: 40px;
$pauseCtaRightDesktopPosition: 40px;

$arrowDownWidth: 11px;
$arrowDownHeight: 32px;

$middleElementsSpacingSmall: 8px;
$middleElementsSpacing: 16px;

$cardPaddingXSmall: 32px 68px 120px;
$cardPaddingSmall: 32px 68px 100px;

$cardPaddingSmallDesktop: 40px 71px 100px 71px;

$cardPaddingRightSmallDesktop: 60px;
$cardPaddingRightXLargeDesktop: 122px;

$cardPaddingRightSmallDesktop: theme.fluid-size(
  $cardPaddingRightSmallDesktop,
  $cardPaddingRightXLargeDesktop,
  theme.$smallDesktop,
  1920px
);

.assetGraphic {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: fit-content;
  border-radius: $mobileBorderRadius;

  background-color: theme.$green-secondary;
  padding: $cardPaddingXSmall;

  @include theme.small-device {
    padding: $cardPaddingSmall;
  }

  @include theme.small-desktop {
    border-radius: $desktopBorderRadius;
    padding: $cardPaddingSmallDesktop;
    padding-right: $cardPaddingRightSmallDesktop;
  }

  @include theme.large-desktop {
    padding-right: $cardPaddingRightXLargeDesktop;
  }

  button.pauseCta {
    position: absolute;
    color: theme.$green-primary;
    bottom: $pauseCtaBottomDevicePosition;
    right: $pauseCtaRightDevicePosition;
    font-family: $amazonEmberDisplay;
    font-size: theme.$fontSizeCta;
    line-height: theme.$fontLineHeightCta;
    padding: 0;

    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 6px;
    }

    @include theme.large-device {
      bottom: $pauseCtaBottomDesktopPosition;
      right: $pauseCtaRightDesktopPosition;
    }
  }
}

.principal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrowDown {
  @include theme.size($arrowDownWidth, $arrowDownHeight);
  color: theme.$green-primary;
  margin-bottom: $middleElementsSpacingSmall;

  @include theme.small-device {
    margin-bottom: theme.fluid-size($middleElementsSpacingSmall, $middleElementsSpacing, theme.$smallDevice, 1920px);
  }
}

.firstPill {
  margin-bottom: $middleElementsSpacingSmall;

  @include theme.small-device {
    margin-bottom: theme.fluid-size($middleElementsSpacingSmall, $middleElementsSpacing, theme.$smallDevice, 1920px);
  }
}

.secondPill {
  margin-bottom: $middleElementsSpacingSmall;

  @include theme.small-device {
    margin-bottom: theme.fluid-size($middleElementsSpacingSmall, $middleElementsSpacing, theme.$smallDevice, 1920px);
  }
}

.lateralPills {
  position: absolute;
  bottom: $lateralPillsBottomPaddingSmall;
  width: 100%;

  @include theme.small-desktop {
    bottom: $lateralPillsBottomPaddingSmallDesktop;
  }
}

.thirdPill {
  position: absolute;
  width: $lateralPillsWidth;
  max-width: $lateralPillsMaxWidth;
  right: $lateralPillsHorizontalMarginXSmall;

  @include theme.small-device {
    right: $lateralPillsHorizontalMarginSmall;
  }

  @include theme.large-device {
    right: $lateralPillsHorizontalMarginLarge;
  }

  @include theme.small-desktop {
    right: $lateralPillsHorizontalMarginSmallDesktop;
  }
}

.fourthPill {
  position: absolute;
  width: $lateralPillsWidth;
  max-width: $lateralPillsMaxWidth;
  left: $lateralPillsHorizontalMarginXSmall;

  @include theme.small-device {
    left: $lateralPillsHorizontalMarginSmall;
  }

  @include theme.large-device {
    left: $lateralPillsHorizontalMarginLarge;
  }

  @include theme.small-desktop {
    left: $lateralPillsHorizontalMarginSmallDesktop;
  }
}
