@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$faqPageQuestionsGap: 56px;

$mobileFaqPageHeaderPadding: 24px 25px;
$mobileFaqPageTitleMargin: 32px 0 0 0;
$mobileFaqPageQuestionsPadding: 40px 25px 80px 25px;

$desktopFaqPageHeaderPadding: 40px 60px;
$desktopFaqPageTitleTopMargin: 64px;
$desktopFaqPageTitleMaxWidth: 52vw;
$desktopFaqPageQuestionsPadding: 140px 63px 160px 60px;

.faqPageHeader {
  padding: $mobileFaqPageHeaderPadding;

  @include theme.small-desktop {
    padding: $desktopFaqPageHeaderPadding;
  }
}

.title {
  color: theme.$green-primary;
  margin: $mobileFaqPageTitleMargin !important;

  @include theme.small-desktop {
    margin-top: $desktopFaqPageTitleTopMargin !important;
    max-width: $desktopFaqPageTitleMaxWidth;
  }
}

.faqPageQuestions {
  display: flex;
  flex-direction: column;
  gap: $faqPageQuestionsGap;
  padding: $mobileFaqPageQuestionsPadding;

  @include theme.small-desktop {
    padding: $desktopFaqPageQuestionsPadding;
  }
}