@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@import '../../../index.scss';

$quoteContainerHeight: 100vh;

$deviceNavbar: 72px;
$mobileLandscapeHeroBannerPadding: 5% 25px;
$mobileHeroBannerPadding: 10% 25px;
$mobileHeroBannerTitleFontSize: 52px;
$mobileHeroBannerTitleLineHeight: 56px;
$mobileHeroBannerSubtitleMargin: 24px 0 0 0;
$mobileHeroBannerCtasContainerTopMargin: 24px;
$mobileHeroBannerCtasContainerGap: 16px;
$mobileHeroBannerCtaWidth: 256px;
$mobileHeroBannerEyebrowMargin: 0 auto 32px auto;
$mobileHeroBannerGap: 32px;
$xSmallMobileHeroBannerGap: 16px;

$mobileQuoteContainerPadding: 40px 25px;
$mobileQuoteContainerQuoteMargin: 48px 0 auto 0;

$desktopNavbar: 96px;
$desktopHeroBannerTitleFontSize: 112px;
$desktopHeroBannerTitleLineHeight: 120px;
$desktopHeroBannerTitleMaxWidth: 80vw;
$desktopHeroBannerSubtitleMargin: 32px 0 0 0;
$desktopHeroBannerSubtitleMaxWidth: 440px;
$desktopHeroBannerCtasContainerTopMargin: 48px;
$desktopHeroBannerCtaWidth: 272px;
$desktopHeroBannerCtaHeight: 80px;
$desktopHeroBannerBrandTabsContainerMargin: auto 0 32px 0;
$desktopHeroBannerEyebrowBottomMargin: 16px;
$desktopHeroBannerEyebrowAnimatedBottomMargin: 48px;
$desktopHeroBannerBrandTabsPlaceholderHeight: 128px;
$desktopHeroBannerBrandTabsPlaceholderBottomMargin: 80px;

$desktopQuoteContainerQuoteMargin: 20vh 0 auto 0;
$desktopQuoteContainerQuoteMaxWidth: 1192px;
$desktopQuoteContainerScrollDownMargin: 0 0 20vh 0;

// specific breakpoint for landscape mobile on the animated hero banner
// since we'll apply small layout adjustments to fit everything on
// such a small space
@mixin animated-hero-mobile-landscape {
  @media (orientation: landscape) and (max-height: 500px) {
    @content;
  }
}

.animatedHeroBanner {
  height: calc(95vh - $deviceNavbar);
  width: 100%;
  padding: $mobileHeroBannerPadding;
  background: theme.$slate-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: $xSmallMobileHeroBannerGap;

  @include animated-hero-mobile-landscape {
    padding: $mobileLandscapeHeroBannerPadding;
  }

  @include theme.small-device {
    gap: $mobileHeroBannerGap;
  }

  @include theme.small-desktop {
    height: calc(100vh - $desktopNavbar);
    gap: 0;
    padding: 0;
    margin-top: 0;
    transition: margin-top 1.2s ease-in-out;

    &.animated {
      // This makes it so that our section "hides" outside of the page
      // to make our background transition correctly
      margin-top: calc(-100vh + $desktopNavbar);
    }
  }
}

.title {
  // On mobile resoltions font will scale by vmax (which is the biggest unit between vw/vh)
  // i.e if the device is on landscape we'll use vw and if it's on portrait we'll use vh
  // that way font size should fit as best as possible
  font-size: 6vmax !important;
  line-height: 1 !important;

  @include theme.small-device {
    font-size: 7vmax !important;
  }

  white-space: pre-line;
  color: theme.$grey-secondary;
  margin: 0 !important;

  @include theme.small-desktop {
    // Specifically for this component we apply a different font size styling
    font-size: theme.fluid-size(
      $mobileHeroBannerTitleFontSize,
      $desktopHeroBannerTitleFontSize,
      theme.$minViewportWidth,
      theme.$maxViewportWidth
    ) !important;
    line-height: theme.fluid-size(
      $mobileHeroBannerTitleLineHeight,
      $desktopHeroBannerTitleLineHeight,
      theme.$minViewportWidth,
      theme.$maxViewportWidth
    ) !important;
    margin: auto 0 0 0 !important;
    max-width: $desktopHeroBannerTitleMaxWidth;
    z-index: 1;
  }
}

.titleFragment {
  color: theme.$green-secondary;
}

.subtitle {
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  color: theme.$grey-secondary;
  opacity: theme.$asxCopyOpacity;
  margin: 0 !important;

  @include theme.small-desktop {
    margin: $desktopHeroBannerSubtitleMargin !important;
    max-width: $desktopHeroBannerSubtitleMaxWidth;
    z-index: 1;
  }
}

.ctasContainer {
  display: flex;
  flex-direction: column;
  gap: $mobileHeroBannerCtasContainerGap;

  @include animated-hero-mobile-landscape {
    flex-direction: row;
  }

  @include theme.large-device {
    margin-top: $desktopHeroBannerCtasContainerTopMargin;
    flex-direction: row;
    z-index: 1;
  }
}

.cta {
  font-size: theme.$fontSizeCta;
  line-height: theme.$fontLineHeightCta;
  flex-shrink: 0;
  width: $mobileHeroBannerCtaWidth;

  @include theme.small-desktop {
    height: $desktopHeroBannerCtaHeight;
    width: $desktopHeroBannerCtaWidth;
  }
}

.brandTabsContainer {
  margin: $desktopHeroBannerBrandTabsContainerMargin;
  transition: all 1.2s ease-in-out;
  flex-shrink: 0;

  &.animated {
    transform: translateY(34.5vh);
    z-index: 1;
  }

  @include theme.small-desktop {
    width: 100%;
    overflow-x: hidden;
    z-index: 1;
  }
}

.eyebrow {
  margin: $mobileHeroBannerEyebrowMargin !important;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  text-align: center;
  color: theme.$grey-secondary;
  opacity: theme.$asxCopyOpacity;
  z-index: 1;

  @include theme.small-desktop {
    margin-bottom: $desktopHeroBannerEyebrowBottomMargin !important;
    transition: all 1.2s ease-in-out;

    &.animated {
      font-size: theme.$fontSizeSubtitle !important;
      line-height: theme.$fontLineHeightSubtitle !important;
      margin-bottom: $desktopHeroBannerEyebrowAnimatedBottomMargin !important;
    }
  }
}

.brandTabsPlaceholder {
  height: $desktopHeroBannerBrandTabsPlaceholderHeight;
  margin-bottom: $desktopHeroBannerBrandTabsPlaceholderBottomMargin;
}

.quoteContainer {
  height: $quoteContainerHeight;
  padding: $mobileQuoteContainerPadding;
  background: theme.$slate-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @include theme.small-desktop {
    padding: 0;
    justify-content: flex-end;
    overflow-x: clip;
  }
}

.backgroundImageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95vh;
  overflow: hidden;
  pointer-events: none;

  @include theme.small-desktop {
    z-index: 1;
    top: $desktopNavbar;
    height: 100vh;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(theme.$slate-dark, 0.6) 40%,
      rgba(theme.$slate-dark, 0.6) 60%,
      theme.$slate-dark 100%
    );

    @include theme.small-desktop {
      z-index: 1;
    }
  }
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;

  @include theme.small-desktop {
    opacity: 0;
    transform: scale(1.08);
    transform-origin: top center;
    transition: all 1.2s ease-in-out;

    &.animated {
      opacity: 1;
      transform-origin: top center;
      transform: scale(1);
    }
  }
}

.quoteWrapper {
  margin: $mobileQuoteContainerQuoteMargin !important;
  text-align: center;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.5s ease;

  &.fadeOut {
    opacity: 0;
  }

  &.fadeIn {
    opacity: 1;
  }

  @include theme.small-desktop {
    margin: $desktopQuoteContainerQuoteMargin !important;
    max-width: $desktopQuoteContainerQuoteMaxWidth;
  }
}

.quote {
  color: theme.$white;
  margin: 0 !important;
}

.scrollDown {
  font-family: $amazonEmberMono !important;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$white;
  opacity: 0.8;
  z-index: 1;

  @include theme.small-desktop {
    margin: $desktopQuoteContainerScrollDownMargin !important;
  }
}
