@use '../../../index.scss' as website;
@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$brandTabsContainerGap: 16px;
$brandTabsContainerMargin: 0 auto;
$profileImageSize: 56px;
$profileCopyWrapperLeftMargin: 16px;

$mobileBrandTabsGap: 8px;
$mobileProfileWrapperBorderRadius: 12px;
$mobileProfileWrapperPadding: 8px;
$mobileProfileImageBorderRadius: 8px;
$mobileBrandTabWidth: 112px;
$mobileBrandTabHeight: 72px;
$mobileBrandTabBorderRadius: 12px;
$mobileBrandTabActiveIndicatorWidth: 32px;
$mobileBrandTabActiveIndicatorHeight: 2px;
$mobileBrandTabActiveIndicatorBorderRadius: 2px;
$mobileBrandTabCarouselButtonWrapperWidth: 96px;
$mobileBrandTabCarouselButtonWrapperHeight: 72px;
$mobileBrandTabCarouselLeftButtonDarkBackground: linear-gradient(90deg, theme.$slate-dark 0%, transparent 100%);
$mobileBrandTabCarouselRightButtonDarkBackground: linear-gradient(90deg, transparent 0%, theme.$slate-dark 100%);
$mobileBrandTabCarouselButtonSize: 32px;

$desktopBrandTabsGap: 20px;
$desktopProfileWrapperPadding: 0 25px 0 0;
$desktopProfileImageBorderRadius: 12px;
$desktopBrandTabWidth: 185px;
$desktopBrandTabHeight: 128px;
$desktopBrandTabBorderRadius: 16px;
$desktopBrandTabActiveIndicatorWidth: 48px;

$divisorWidth: 2px;
$divisorHeight: 24px;
$divisorBorderRadius: 16px;
$divisorMargin: auto 32px auto 0;

$activeButtonWidth: 32px;
$activeButtonHeight: 2px;
$activeButtonBorderRadius: 2px;

$transitionDuration: 1s;

$transitionEasing: linear(
  0,
  0.012 0.9%,
  0.049 2%,
  0.409 9.3%,
  0.513 11.9%,
  0.606 14.7%,
  0.691 17.9%,
  0.762 21.3%,
  0.82 25%,
  0.868 29.1%,
  0.907 33.6%,
  0.937 38.7%,
  0.976 51.3%,
  0.994 68.8%,
  1
);

.brandTabsContainer {
  display: flex;
  flex-direction: column;
  gap: $brandTabsContainerGap;
  max-width: 100%;
  margin: $brandTabsContainerMargin;
}

.brandTabsWrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.brandTabs {
  --offsetStart: 0;
  --start: 0;
  --offsetEnd: 100%;
  --end: 100%;
  mask-image: linear-gradient(
    90deg,
    transparent var(--offsetStart),
    theme.$white var(--start),
    theme.$white var(--end),
    transparent var(--offsetEnd)
  );
  display: flex;
  gap: $mobileBrandTabsGap;
  position: relative;
  overflow-x: auto;
  transition: mask-image 0.3s ease-in-out;

  &.showButtonLeft {
    --offsetStart: 10%;
    --start: 20%;
  }

  &.showButtonRight {
    --end: 80%;
    --offsetEnd: 90%;
  }
  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @include theme.small-desktop {
    gap: $desktopBrandTabsGap;
  }
}

.profileWrapper {
  display: flex;
  border-radius: $mobileProfileWrapperBorderRadius;
  background: theme.$slate-dark;
  padding: $mobileProfileWrapperPadding;
  z-index: 1;

  @include theme.small-desktop {
    border: 0;
    background: none;
    padding: $desktopProfileWrapperPadding;
  }

  .profileImage {
    @include theme.size($profileImageSize);
    border-radius: $mobileProfileImageBorderRadius;

    @include theme.small-desktop {
      border-radius: $desktopProfileImageBorderRadius;
    }
  }

  .profileCopyWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: $profileCopyWrapperLeftMargin;
  }

  .profileName,
  .profileOccupation {
    font-size: #{theme.$fontSizeBody} !important;
    line-height: #{theme.$fontLineHeightBody} !important;
    font-family: website.$amazonEmberDisplay;
    margin: 0 !important;
    color: theme.$grey-secondary;

    @include theme.small-desktop {
      white-space: nowrap;
    }
  }

  .profileOccupation {
    opacity: 0.85;
  }
}

.brandTab {
  position: relative;
  min-width: $mobileBrandTabWidth;
  width: $mobileBrandTabWidth;
  height: $mobileBrandTabHeight;
  background: rgba(theme.$slate-dark, 0.5) !important;
  border-radius: $mobileBrandTabBorderRadius;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  background-color: transparent;
  transform-origin: left;
  transition-duration: $transitionDuration;
  transition-property: width, border, background-color;
  transition-timing-function: $transitionEasing;

  @include theme.small-desktop {
    min-width: theme.fluid-size(
      $mobileBrandTabWidth,
      $desktopBrandTabWidth,
      theme.$smallDesktop,
      theme.$maxViewportWidth
    );
    width: theme.fluid-size($mobileBrandTabWidth, $desktopBrandTabWidth, theme.$smallDesktop, theme.$maxViewportWidth);
    height: theme.fluid-size(
      $mobileBrandTabHeight,
      $desktopBrandTabHeight,
      theme.$smallDesktop,
      theme.$maxViewportWidth
    );
  }

  &.animated {
    background: rgba(theme.$slate-dark, 0.5);
  }

  &:hover {
    background-color: theme.$slate-dark !important;
    border-color: theme.$white !important;

    .brandIcon {
      color: theme.$white;
      opacity: 1;
    }
  }

  &:focus-visible {
    outline: 1px solid rgba(theme.$white, 0.35);
    outline-offset: -4px;

    .brandIcon {
      color: theme.$white;
      opacity: 1;
    }
  }

  .greenLine {
    width: $mobileBrandTabActiveIndicatorWidth;
    height: $mobileBrandTabActiveIndicatorHeight;
    border-radius: $mobileBrandTabActiveIndicatorBorderRadius;
    background: theme.$green-secondary;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    @include theme.small-desktop {
      width: $desktopBrandTabActiveIndicatorWidth;
      left: calc($desktopBrandTabWidth / 2);
    }
  }

  .brandIcon {
    min-width: $mobileBrandTabWidth;
    display: flex;
    justify-content: center;
    transform: scale(0.8);

    @include theme.small-desktop {
      min-width: theme.fluid-size(
        $mobileBrandTabWidth,
        $desktopBrandTabWidth,
        theme.$smallDesktop,
        theme.$maxViewportWidth
      );
      transform: scale(0.7);
    }

    svg {
      color: theme.$grey-secondary;
      transition-duration: $transitionDuration;
      transition-property: opacity;
      transition-timing-function: $transitionEasing;
    }
  }

  &.isActive {
    background-color: theme.$slate-dark !important;
    transform-origin: right;

    &:focus-visible {
      outline-offset: -1px;
    }

    .greenLine {
      display: block;
    }

    @include theme.small-desktop {
      flex-shrink: 0;
      max-width: fit-content;
    }

    .brandIcon {
      animation: fadeIn 0.3s ease forwards;

      svg {
        color: theme.$white;
        opacity: 1;
      }
    }
  }
}

.buttonWrapperRight,
.buttonWrapperLeft {
  width: $mobileBrandTabCarouselButtonWrapperWidth;
  height: $mobileBrandTabCarouselButtonWrapperHeight;
  position: absolute;
  display: none;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  &.showButtonRight,
  &.showButtonLeft {
    display: flex;
  }
}

.buttonWrapperRight {
  justify-content: flex-end;
  right: 0;
}

.buttonWrapperLeft {
  justify-content: flex-start;
  left: 0;
  button {
    rotate: 180deg;
  }
}

.scrollButton {
  @include theme.size($mobileBrandTabCarouselButtonSize);
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: theme.$white !important;
  color: theme.$green-primary !important;
  pointer-events: all;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
