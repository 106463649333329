@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

.imageHeader {
  flex-basis: 48.5%;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
  top: 80px;
  position: sticky;
  height: fit-content;
  background-color: theme.$grey-secondary;
  z-index: 1;
  display: grid;
  gap: 16px;
  padding-bottom: 32px;
  @include theme.large-device {
    top: 17vh;
    z-index: 4;
  }

  @include theme.small-desktop {
    top: 17vh;
    z-index: 1;
  }
}

.fixedTaglineAndTitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include theme.large-device {
    gap: 32px;
  }
}

.counter {
  justify-self: flex-end;
  font-size: theme.$fontSizeLabel !important;
  line-height: 16px !important;
  margin: 0 !important;
  font-family: website.$amazonEmberMono !important;
  color: theme.$green-primary;
}

.imageWrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 7 / 5;

  @include theme.large-device {
    aspect-ratio: 1 / 1;
    max-width: 736px;
    height: theme.fluid-size(250px, 736px, theme.$minViewportWidth, theme.$maxViewportWidth);
  }
}

.slide {
  position: absolute;
  opacity: 0;
  transition: opacity 0.45s ease;
  pointer-events: none;
  height: 100%;
  width: 100%;
  border-radius: 32px;
  overflow: hidden;

  &::before {
    @include theme.pseudo;

    width: 100%;
    height: 168px;
    bottom: 0;
    border-radius: 0px 0px 32px 32px;
    background: linear-gradient(0deg, rgba(9, 12, 17, 0.6) 41.74%, rgba(9, 12, 17, 0) 100%);
    z-index: 1;
  }

  &.hasQuote {
    @include theme.small-desktop {
      &::before {
        height: 282px;
        background: linear-gradient(
          0deg,
          rgba(9, 12, 17, 0.7) 0%,
          rgba(9, 12, 17, 0.6) 56.33%,
          rgba(9, 12, 17, 0) 100%
        );
      }
    }
  }

  &.hasLottie {
    &::before {
      content: none;
    }
  }
}

.slide.active {
  opacity: 1;
  pointer-events: auto;
}

.byLineWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  width: calc(100% - 48px);
  bottom: 24px;
  left: 24px;
  color: theme.$grey-secondary;
  z-index: 2;

  &.hasQuote {
    bottom: 22px;
    left: 26px;
  }

  &.hasVideo {
    width: 60%;
    @include theme.small-desktop {
      width: 78%;
    }
  }
}

.quote {
  font-size: theme.$fontSizeSubtitle !important;
  line-height: theme.$fontLineHeightSubtitle !important;
  display: none;
  margin: 0 !important;

  @include theme.small-desktop {
    display: block;
  }
}

.byLine {
  font-size: theme.$fontSizeBodySmall !important;
  line-height: theme.$fontLineHeightBodySmall !important;
  margin: 0 !important;
}

.image {
  @include theme.size(100%, 100%);
  object-fit: cover;
}

.lottieContent {
  position: relative;
  @include theme.size(100%, 100%);
  background-color: theme.$grey-tertiary;
}

.pauseButton {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 100%;
  background-color: theme.$grey-tertiary !important;
  display: grid;
  justify-content: center;
  align-items: center;
  bottom: 24px;
  right: 24px;
  border: 2px solid rgba(theme.$green-primary, 0.5) !important;

  @include theme.small-desktop {
    width: 56px;
    height: 56px;
    bottom: 16px;
    right: 16px;
  }
}

.lottieWrapper {
  position: relative;
  @include theme.size(100%, 100%);

  div:not(.pauseIcon) {
    @include theme.size(100%, 100%);
    svg {
      object-fit: cover;
    }
  }
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
