@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$iconSize: 24px;

.componentWrapper {
  @include theme.small-desktop {
    aspect-ratio: 16/9;
  }
}
.textAndCard {
  margin: 0 auto;
  position: relative;
  display: flex;
  padding: 40px 25px 0;
  flex-direction: column;
  gap: 40px;
  align-items: center;

  @include theme.small-desktop {
    max-width: theme.$maxViewportWidth;
    height: 100%;
    padding: 104px theme.fluid-size(60px, 168px, theme.$largeDevice, theme.$maxViewportWidth) 0 60px;
    flex-direction: row;
    gap: theme.fluid-size(60px, 128px, theme.$largeDevice, theme.$maxViewportWidth);
    align-items: flex-start;
  }
  @include theme.small-desktop {
    align-items: center;
  }
}

.titleAndImageSection {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @include theme.small-desktop {
    width: calc(50% + 128px);
    justify-content: space-evenly;
    margin-right: theme.fluid-size(0px, 90px, theme.$largeDevice, theme.$maxViewportWidth);
  }
}

.title {
  color: theme.$grey-secondary;
  margin-bottom: 24px !important;
  letter-spacing: -1.32px !important;

  @include theme.small-desktop {
    margin-bottom: 40px !important;
  }
}

.highlitedTitle {
  color: theme.$green-secondary;
}

.copy {
  margin-bottom: 24px !important;
  color: theme.$grey-secondary !important;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  max-width: 475px;
  opacity: theme.$asxCopyOpacity;
}

.link {
  color: theme.$grey-secondary !important;
  span {
    line-height: 16px !important;
  }
}

.imagesWrapper {
  position: relative;
  width: 100%;
  height: 50%;
}

.bigImageWrapper {
  position: absolute;
  width: theme.fluid-size(220px, 440px, theme.$largeDevice, theme.$maxViewportWidth);
  border-radius: 32px;
  left: theme.fluid-size(1px, 72px, theme.$largeDevice, theme.$maxViewportWidth);
  bottom: 0;
  aspect-ratio: 55/37;
}

.smallImageWrapper {
  position: absolute;
  width: theme.fluid-size(101px, 188px, theme.$largeDevice, theme.$maxViewportWidth);
  border-radius: 32px;
  left: 71.5%;
  top: theme.fluid-size(97px, 1px, theme.$largeDevice, theme.$maxViewportWidth);
  aspect-ratio: 1/1;
}

.smallImage,
.bigImage {
  border-radius: 24px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bigImage {
  border-radius: 24px;

  @include theme.small-desktop {
    border-radius: 32px;
  }
}

.bigImageMobileWrapper {
  border-radius: 24px;
  width: 100%;
  height: theme.fluid-size(200px, 400px, theme.$minViewportWidth, theme.$largeDevice);
}

.cardWrapper {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(theme.$white, 0.05) 0%, rgba(theme.$white, 0.05) 100%), theme.$slate-dark;

  @include theme.small-desktop {
    padding: theme.fluid-size(24px, 40px, theme.$largeDevice, theme.$maxViewportWidth)
      theme.fluid-size(24px, 48px, theme.$largeDevice, theme.$maxViewportWidth)
      theme.fluid-size(24px, 48px, theme.$largeDevice, theme.$maxViewportWidth);
    border-radius: 32px;
    width: 60%;
    min-width: theme.fluid-size(500px, 676px, theme.$largeDevice, theme.$maxViewportWidth);
  }

  @media only screen and (min-width: 1600px) {
    width: 50%;
  }
}

.cardTitle {
  color: theme.$green-secondary;
  margin: 0 !important;
}

.cardTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include theme.small-desktop {
    gap: 32px;
    padding-right: 20px;
  }
}
.cardCopyWrapper {
  display: flex;
  flex-direction: column;

  gap: 16px;
  @include theme.small-desktop {
    gap: 40px;
  }
}

.cardCopy {
  white-space: pre-line;
  p {
    color: theme.$grey-secondary !important;
    opacity: theme.$asxCopyOpacity;
    font-size: theme.$fontSizeBody !important;
    line-height: theme.$fontLineHeightBody !important;
    margin: 0 !important;
  }

  svg {
    max-height: $iconSize;
    max-width: $iconSize;
  }
}

.cardListItems {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include theme.small-desktop {
    gap: 24px;
  }
}

.cardListItem {
  display: flex;
  align-items: center;
  min-height: 40px;

  @include theme.small-desktop {
    min-height: 48px;
  }
}

.cardListItemNumberWrapper {
  @include theme.size(40px);

  position: absolute;
  border-radius: 100%;
  background: rgba(theme.$white, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;

  @include theme.small-desktop {
    @include theme.size(48px);
  }
}

.cardListItemNumber {
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  color: theme.$grey-secondary !important;
  font-family: website.$amazonEmberMono !important;
  margin: 0 !important;
}

.cardListItemCopy {
  color: theme.$grey-secondary !important;
  opacity: theme.$asxCopyOpacity;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  margin: 0 0 0 52px !important;

  @include theme.small-desktop {
    margin: 0 0 0 72px !important;
    line-height: 28px !important;
  }
}

.ctasWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;

  button,
  a {
    width: 100% !important;
    white-space: nowrap;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 16px;
    line-height: 16px;
    height: 64px;
    div {
      gap: 4px !important;
    }
    svg {
      position: absolute;
      top: 1px;
    }
  }

  @include theme.medium-device {
    flex-direction: row;

    button,
    a {
      width: 50% !important;
    }
  }

  @include theme.small-desktop {
    margin-top: theme.fluid-size(20px, 100px, theme.$largeDevice, theme.$maxViewportWidth);
    button,
    a {
      height: 80px;
      svg {
        top: 0;
      }
      div {
        gap: 12px !important;
      }
    }
  }
}
