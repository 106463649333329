@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;

$desktopMaxWidth: 1192px;

.quote {
  font-size: theme.$fontSizeHeading04 !important;
  line-height: theme.$fontLineHeightHeading04 !important;
  text-align: center;

  @include theme.small-desktop {
    max-width: $desktopMaxWidth;
  }
  span {
    transition: color 0.3s ease;
  }
}
