@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

.customGraphic {
  position: relative;
  color: theme.$green-primary;
  max-width: 1920px;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
}

.heading {
  padding: 0 25px 48px;
  margin-bottom: 48px;
  @include theme.medium-device {
    padding: 0 60px 80px;
    margin-bottom: 80px;
  }
}

.tagline {
  font-family: website.$amazonEmberMono;
  margin-bottom: 16px;

  @include theme.small-desktop {
    margin-bottom: 32px;
  }
}

.titleWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @include theme.small-desktop {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}

.title {
  flex: 2;
  white-space: pre-line;
  h3 {
    letter-spacing: -1.32px !important;
    margin: 0 !important;
  }
}

.description {
  letter-spacing: -0.2px;
  max-width: 736px;

  @include theme.small-desktop {
    width: 38vw;
    white-space: pre-line;
  }
}

.pauseButton {
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 100%;
  background-color: theme.$grey-tertiary !important;
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: -47px;

  @include theme.medium-device {
    width: 56px;
    height: 56px;
    top: -65px;
  }
}

.detailsContent {
  padding: 0 25px;
  @include theme.small-desktop {
    padding: 0 60px;
  }
}

.detailsWrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  background-color: theme.$white;
  padding: 16px 12px;
  border-radius: 8px;
  width: 100%;
  position: relative;

  @include theme.small-desktop {
    flex-direction: row;
    width: fit-content;
    padding: 16px;
    border-radius: 12px;
    flex-wrap: wrap;
    gap: 35px;
  }
}

.details {
  display: flex;
  align-items: center;
  gap: 16px;
  @include theme.small-desktop {
    gap: 12px;
  }
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.line {
  width: 26px;
  height: 2px;
  background-color: theme.$green-primary;
}

.copy {
  color: theme.$green-primary;
  font-size: theme.$fontSizeLabel !important;
  line-height: theme.$fontLineHeightLabel !important;
  margin: 0 !important;
  font-family: website.$amazonEmberMono;
  white-space: nowrap;
}

.footer {
  padding: 0 25px;
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  margin-top: 32px;
  gap: 36px;

  @include theme.small-desktop {
    padding: 0 60px 0 71px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.footerIconWrapper {
  @include theme.size(56px);
  background: theme.$white;
  padding: 16px;
  border-radius: 32px;
}

.footerIcon {
  @include theme.size(24px);
  background: theme.$green-primary;
  color: theme.$white;
  border-radius: 32px;
}

.footerLinkWrapper {
  display: flex;
  gap: 8px;
}

.footerParagraph {
  color: theme.$green-primary;
  font-size: theme.$fontSizeBody !important;
  line-height: theme.$fontLineHeightBody !important;
  margin: 0 !important;
  letter-spacing: -0.2px;
}

.footerLink {
  font-weight: 700;
}

.footerButton {
  background-color: transparent !important;
  border: 2px solid !important;
}

.lottieWrapper {
  margin: 0 3.13vw 0 theme.fluid-size(25px, 47px, theme.$minViewportWidth, theme.$maxViewportWidth);
  position: relative;
}

.years {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 48px 9.5vw;

  @include theme.medium-device {
    margin-left: theme.fluid-size(84px, 180px, theme.$mediumDevice, theme.$maxViewportWidth);
  }

  span {
    font-family: website.$amazonEmberMono;
    font-size: theme.$fontSizeLabel;
    line-height: theme.$fontLineHeightLabel;
    opacity: 0.85;
    position: relative;
    &::before {
      content: ' ';
      width: 1px;
      height: 8px;
      position: absolute;
      top: -14px;
      right: 50%;
      transform: translateX(-50%);
      background-color: theme.$green-primary;
      opacity: 0.4;
    }
    &:last-of-type {
      &::before {
        right: 1px;
        transform: unset;
      }
    }
  }
}

.emissionsAndLottie {
  position: relative;
  @include theme.medium-device {
    display: flex;
    align-items: flex-end;
    height: 31vw;
    max-height: 582px;
  }
}

.emissions {
  display: flex;
  gap: 1.25vw;
  position: absolute;
  height: 92%;
  z-index: 1;
  top: 12px;

  span {
    font-family: website.$amazonEmberMono;
    font-size: theme.$fontSizeLabel;
    line-height: theme.$fontLineHeightLabel;
    opacity: 0.85;
  }

  @include theme.medium-device {
    margin-right: 1.56vw;
    position: relative;
    height: 100%;
    top: unset;
  }
}

.emissionsItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.5vw;
}

.emissionsCopy {
  position: absolute;
  top: -38px;
  left: 0;
  z-index: 1;
  max-width: 100px;

  @include theme.medium-device {
    position: relative;
    writing-mode: vertical-rl;
    white-space: nowrap;
    align-self: center;
    left: unset;
    top: unset;
    transform: rotate(180deg);
    max-width: unset;
  }
}
