@use '~@amzn/sss-website-theme/dist/theme/index.module.scss' as theme;
@use 'src/index.scss' as website;

$pillTransition: all 0.25s 0.5s ease-in-out;
$pillTransitionProperties: background-color, color;
$pillBorderRadius: 12px;

.assetPill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.pill {
  font-family: website.$amazonEmberDisplay;
  padding: 6px 16px;
  border: 1px solid rgba(theme.$green-primary, 0.2);
  border-radius: $pillBorderRadius;
  background-color: theme.$green-secondary;
  color: theme.$green-primary;
  margin-bottom: 8px;
  transition: $pillTransition;
  transition-property: $pillTransitionProperties;
}

.content {
  display: inline-block;
  font-size: theme.$fontSizeBody;
  line-height: theme.$fontLineHeightBody;
  color: rgba(theme.$green-primary, 0.85);
  transition: $pillTransition;
  transition-property: $pillTransitionProperties;
  text-align: center;
  opacity: theme.$asxCopyOpacity;

  @include theme.small-desktop{
    white-space: nowrap;
  }
}

.isActive {
  .pill {
    background-color: theme.$green-primary;
    color: theme.$green-secondary;
  }
  .content {
    color: theme.$green-primary;
  }
}
